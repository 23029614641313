import React, { useState, useEffect, useContext } from 'react'
import SOKSidebar from './SOKSidebar'
import EnterClick from './EnterClick'
import { Link } from "react-router-dom";
import { UserContext } from './App'
import axios from 'axios';

export default function LeaderboardsStage() {
  const [leaderboards, setLeaderboards] = useState([])
  const { userData } = useContext(UserContext)

  function handleEnterClick(contestId) {
    EnterClick(contestId, function(data) {
      axios.get(`${process.env.REACT_APP_API_URL}leaderboards`)
        .then(res => {
          setLeaderboards(data)
        })
    })
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}leaderboards`)
      .then(res => {
        setLeaderboards(res.data)
      })
  }, [userData])

  return (
    <div className="outer-wrapper">
      <div className="content-wrapper">
        <div className="banner-container">
          <ul>
            <li>
              <div>
                <Link to="/about">
                 <img className="homepage-banner1" src="https://storage.googleapis.com/sok-images/free-contest-free-pps-banner.gif" alt="First Banner" />
                </Link>
              </div>
            </li>
            <li className="banner2">
              <div>
                <a href="https://horsetourneys.com" target="_blank">
                 <img className="homepage-banner2" src="https://storage.googleapis.com/sok-images/horsetourneys-sample-banner2.gif" alt="Horse Tourneys Banner" />
                </a>
              </div>
            </li>
            <li className="banner3">
              <div>
                <Link to="/contests/106">
                 <img className="homepage-banner-nhc" src="https://storage.googleapis.com/sok-images/nhc-contest-banner.gif" alt="NHC Contest Banner" />
                </Link>
              </div>
            </li>
          </ul>
        </div>
          <main>
            <SOKSidebar />
            <div className="main-homepage">
              <div className="contests-title-row">
                <div className="homepage-header">Leaderboards</div>
              </div>
              <div className="leaderboards">
                {leaderboards.map(contest => 
                  <Leaderboard contest={contest} handleEnterClick={handleEnterClick} userData={userData} key={contest.id} />
                )}
              </div>
            </div>
          </main>
      </div>
    </div>
  )
}

const Leaderboard = ({ contest, handleEnterClick, userData }) => {
  return ( <Link to={`/contests/${contest.id}/standings`}>
    <div className="leaderboard-column">
      <div className="leaderboard-contest-title-row">
        <div className="leaderboard-header">{ contest.name }</div>
        <div className="gradient-rule"></div>
        <ol>
          { contest.standings.map(standing =>
            <li className={`${standing.current_player ? 'logged-in-player-row' : 'player-row'} ${standing.rank > 999 ? 'four-digit' : ''}`} key={standing.id}>             <div className="rank"><p>{ standing.rank }</p></div>
              <div className="player"><p>{ standing.handle }</p></div>
              <div className="total"><p>{ parseFloat(standing.total).toFixed(2) }</p></div>
            </li>
          )}
          { contest.in_top_10 && (
            <li className="not-entered-player-row">
              <div className="not-entered-column"><p>Congratulations on being in the top 10!</p></div>
            </li>
          )}
          { userData.token && !contest.entered && !contest.qualification_required && contest.contest_type !== 'Series' && (
            <li className="not-entered-player-row">
              <div className="not-entered-column"><p>You have not entered.</p></div>
              <div className="total">
                <div className="outlined-button-smaller" onClick={() => handleEnterClick(contest.id)}>Enter</div>
              </div>
            </li>
          )}
          { contest.qualification_required && !contest.entered && (
            <li className="not-entered-player-row">
              <div className="not-entered-column"><p>Qualifiers Only</p></div>
            </li>
          )}
          <li className="total-player-row">
            <div className="total-player-count"><p>Total { contest.total_entries } entries.</p></div>
          </li>
          <li className="full-standings-row">
            <div className="see-full-standings">
              <p><Link to={`/contests/${contest.id}/standings`}>Full Standings</Link></p>
            </div>
          </li>
        </ol>
      </div>
    </div>
    </Link>
  )
}
import React, { useState, useContext } from 'react';
import {Helmet} from "react-helmet";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CountryOptions from './CountryOptions'
import StateProvinceOptions from './StateProvinceOptions'
import { UserContext } from './App'
import SOKSidebar from './SOKSidebar'
import axios from 'axios';

export default function TShirtOrder() {
  const [submitted, setSubmitted] = useState(false)
  const [orderMessage, setOrderMessage] = useState('Loading...')
  const { userData } = useContext(UserContext)

  function handleSubmit(values, setSubmitting) {
    axios.post(`${process.env.REACT_APP_API_URL}tshirt-order`, {
      order: values
    }).then(res => {
      window.scrollTo(0, 0)
      setOrderMessage('Your order has been received. Thank you!')
      setSubmitting(false)
      setSubmitted(true)
    })
  }

  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | T-Shirt Selection Form</title>
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="main-homepage">
            <div className="contests-title-row">
              <div className="homepage-header">Select a T-Shirt! (Best if logged in)</div>
            </div>
            { !submitted && userData.loaded ? (
              <div className="registration-container">
                <div className="registration-details">
                  <div className="register1-button">
                    <div className="registration-info-container">
                      <div className="register-title-text">Fill out the form below to receive your T-shirt.</div>
                    </div>
                  </div>
                  <div className="account-wrapper">
                    <div className="t-shirt-images">
                      <img src="https://storage.googleapis.com/sok-images/sok-black-t-shirt.png" alt="Sport of Kings Black T-Shirt" width="400" />
                    </div>
                    <Formik
                      initialValues={{
                        tshirt_size: '',
                        email: userData.player ? userData.player.email : '',
                        first_name: userData.player ? userData.player.first_name : '',
                        last_name: userData.player ? userData.player.last_name : '',
                        address1: userData.player ? userData.player.address1 : '',
                        address2: userData.player ? userData.player.address2 : '',
                        city: userData.player ? userData.player.city : '',
                        state: userData.player ? userData.player.state : '',
                        postal_code: userData.player ? userData.player.postal_code : '',
                        country: userData.player ? userData.player.country : 'US',
                        phone: userData.player ? userData.player.phone: '',
                      }}
                      validate={values => {
                        const errors = {};
                        if (!values.tshirt_size) {
                          errors.tshirt_size = 'Required';
                        }
                        if (!values.email) {
                          errors.email = 'Required';
                        }
                        if (!values.first_name) {
                          errors.first_name = 'Required';
                        }
                        if (!values.last_name) {
                          errors.last_name = 'Required';
                        }
                        if (!values.address1) {
                          errors.address1 = 'Required';
                        }
                        if (!values.city) {
                          errors.city = 'Required';
                        }
                        if (!values.postal_code) {
                          errors.postal_code = 'Required';
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values, setSubmitting)
                      }}
                    >
                      {({ errors, touched, isSubmitting }) => (
                        <Form>
                          <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="tshirt_size">T-Shirt Size</label>
                            </div>
                            <div className="text-field-container">
                              <Field name="tshirt_size" as="select" id="tshirt_size" className="state-menu">
                                <option value="">Select a Size...</option>
                                <option value="S">Unisex S</option>
                                <option value="M">Unisex M</option>
                                <option value="L">Unisex L</option>
                                <option value="XL">Unisex XL</option>
                                <option value="XXL">Unisex XXL</option>
                                <option value="XXXL">Unisex XXXL</option>
                                <option value="XXXXL">Unisex XXXXL</option>
                                {/*<option value="W-S">Women&rsquo;s American Apparel S</option>
                                <option value="W-M">Women&rsquo;s American Apparel M</option>
                                <option value="W-L">Women&rsquo;s American Apparel L</option>
                                <option value="W-XL">Women&rsquo;s American Apparel XL</option>*/}
                              </Field>
                            </div>
                          </div>
                          <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="email">Email</label>
                            </div>
                            <div className="text-field-container">
                              <Field type="email" id="email" name="email" />
                              {errors.email && touched.email ? (
                                <div>{errors.email}</div>
                              ) : null}
                            </div>
                          </div>
                         <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="first_name">First Name</label>
                            </div>
                            <div className="text-field-container">
                              <Field type="text" id="first_name" name="first_name" />
                              {errors.first_name && touched.first_name ? (
                                <div>{errors.first_name}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="last_name">Last Name</label>
                            </div>
                            <div className="text-field-container">
                              <Field type="text" id="last_name" name="last_name" />
                              {errors.last_name && touched.last_name ? (
                                <div>{errors.last_name}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="address-line-one">Address Line 1</label>
                            </div>
                            <div className="text-field-container">
                              <Field type="text" id="address1" name="address1" />
                              {errors.address1 && touched.address1 ? (
                                <div>{errors.address1}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="address-line-two">Address Line 2</label>
                            </div>
                            <div className="text-field-container">
                              <Field type="text" id="address2" name="address2" />
                            </div>
                          </div>
                          <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="address-city">City</label>
                            </div>
                            <div className="text-field-container">
                              <Field type="text" id="city" name="city" />
                              {errors.city && touched.city ? (
                                <div>{errors.city}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="state">State</label>
                            </div>
                            <div className="text-field-container">
                              <Field name="state" as="select" id="state" className="state-menu">
                                <StateProvinceOptions />
                              </Field>
                            </div>
                          </div>
                          <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="postal_code">Zip/Postal Code</label>
                            </div>
                            <div className="text-field-container">
                              <Field type="text" id="postal_code" name="postal_code" />
                              {errors.postal_code && touched.postal_code ? (
                                <div>{errors.postal_code}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="country">Country</label>
                            </div>
                            <div className="text-field-container">
                              <Field name="country" as="select" id="country" className="country-menu">
                                <CountryOptions />
                              </Field>
                              {errors.country && touched.country ? (
                                <div>{errors.country}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="save-changes-container">
                            <input type="submit" value="SUBMIT" />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            ) : (
              <div className="registration-container">
                <div className="registration-details">
                  <div className="register1-button">
                    <div className="registration-info-container">
                      <div className="register-title-text">{ orderMessage }</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import { useParams } from "react-router-dom";
import SOKSidebar from './SOKSidebar'
import axios from 'axios';
import Moment from 'moment';
import moment from 'moment-timezone';
import Countdown from 'react-countdown';
import $ from 'jquery'
import Carousel from 'react-elastic-carousel'

export default function ContestOdds() {
  const [contest, setContest] = useState({})
  const [race, setRace] = useState({})
  let { contestId } = useParams();

  const breakPoints = [
  { width: 1, itemsToShow: 3, itemsToScroll: 1},
  { width: 550, itemsToShow: 3, itemsToScroll: 1 },
  { width: 768, itemsToShow: 3, itemsToScroll: 1 },
  { width: 1200, itemsToShow: 3, itemsToScroll: 1 }
  ]

  function togglePicks(entryId, index) {
    if (race.entries[index].picks) {
      $('#entrant' + index + '-player-picks').slideUp()
      setRace(prevRace => ({...prevRace, entries: prevRace.entries.map((s, i) => {
        if (i === index) {
          s.picks = null
        }
        return s;
      })}))
    }
    else {
      if (race.betting_open) {
        alert('Betting for this race is still open.')
      }
      else {
        axios.get(`${process.env.REACT_APP_API_URL}entries/${entryId}/picks?contest_id=${contestId}`)
          .then(res => {
            setRace(prevRace => ({...prevRace, entries: prevRace.entries.map((s, i) => {
              if (i === index) {
                s.picks = res.data
              }
              return s;
            })}))
            $('#entrant' + index + '-player-picks').slideDown()
          })
      }
    }
  }

  function handleRaceClick(raceId, delta, selectedEntry) {
    axios.get(`${process.env.REACT_APP_API_URL}races/${raceId}?odds=true&contest_id=${contestId}`)
      .then(res => {
        $('.odds-player-picks').hide()
        res.data.delta = delta
        if (selectedEntry) {
          let selectedIndex = res.data.entries.findIndex(e => e.id === parseInt(selectedEntry))
          if (selectedIndex > -1) {
            axios.get(`${process.env.REACT_APP_API_URL}entries/${selectedEntry}/picks?contest_id=${contestId}`)
              .then(pres => {
                res.data.entries[selectedIndex].picks = pres.data
                setRace(res.data)
                $('#entrant' + selectedIndex + '-player-picks').show()
              })
          }
        }
        else {
          setRace(res.data)
        }
      })
  }

  function handleRacePager(offset) {
    const currentContestRaceDelta = contest.contest_races.findIndex(cr => cr.race.id === race.id)
    const nextContestRaceDelta = currentContestRaceDelta + offset
    if (contest.contest_races[nextContestRaceDelta]) {
      handleRaceClick(contest.contest_races[nextContestRaceDelta].race.id, contest.contest_races[nextContestRaceDelta].delta);
    }
  }

  Moment.locale('en')

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    let selectedRace = query.get('race')
    axios.get(`${process.env.REACT_APP_API_URL}contests/${contestId}`)
      .then(res => {
        setContest(res.data)
        if (selectedRace) {
          let selectedEntry = query.get('entry')
          handleRaceClick(selectedRace, res.data.contest_races.find(cr => cr.race.id === parseInt(selectedRace)).delta, selectedEntry)
        }
        else {
          const openRaces = res.data.contest_races.filter(contestRace => contestRace.race.betting_open)
          handleRaceClick(openRaces[0].race.id, openRaces[0].delta)
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contestId])

  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>{contest.name ? `${contest.name} | Odds | Sport of Kings` : 'Odds | Sport of Kings'}</title>
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="main-make-picks">
            <div className="make-picks-column">
              <div className="contests-title-row">
                <div className="make-picks-header">Odds</div>
              </div>
              <div className="make-picks-container">
                <div className="contest-details">
                  <div className="contest-title">
                    <div className="contest1-button">
                      <div className="contest-button-container">
                        <div className="contest-title-text">{contest.name}</div>
                        <div className="carat"><img src="/img/white-carat-down.svg" alt="" className="changeCarat" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="contest1-reveal">
                    { race.id && (
                      <div className="make-picks-race-detail-row">
                        <div className="event-number">
                          <p>Event {race.delta}</p>
                        </div>
                        <div className="event-details">
                          <p>{race.short_name} <span className="gray-text">({Moment(race.race_starts_at).format('M/D')})</span></p>
                        </div>
                        <div className="event-time">
                          <p><span className="gray-text">Post time:</span> {Moment(race.race_starts_at).tz(moment.tz.guess()).format('h:mma z')}</p>
                        </div>
                        <div className="picks-deadline">Picks Deadline:&nbsp;
                          <span className="timer-color">
                          { race.betting_open ? 
                            <Countdown
                              key={contest.contest_type == 'Pick N Pray' ? contest.betting_closes_at : race.betting_closes_at}
                              date={contest.contest_type == 'Pick N Pray' ? contest.betting_closes_at : race.betting_closes_at}
                              daysInHours={true}
                            />
                          :
                            <span>Closed</span>
                          }
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="make-picks-event-row">
                      <ol className="upcoming-races">
                        { contest.contest_races && 
                          <Carousel className="upcoming-races" breakPoints={breakPoints}>
                            {contest.contest_races.map((contestRace, index) =>
                              <div key={contestRace.id} className={(race && race.id === contestRace.race.id) ? 'event-card-active' : 'event-card'} onClick={() => handleRaceClick(contestRace.race.id, contestRace.delta)}>
                                <div className="event-date">{ Moment(contestRace.race.race_starts_at).format('M/D') }</div>
                                <div className="event-track-and-race">{contestRace.race.short_name}</div>
                              </div>
                            )}
                          </Carousel>
                        }
                      </ol>
                    </div>
                    { race.id && (
                      <div className="odds-race-container">
                        <div className="odds-sub-container">
                          <div className="total-picks-for-race">
                            Picks so far: <span className="white-text">{ contest.contest_races.find(contestRace => contestRace.race.id === race.id).picks_count }</span>
                          </div>
                          <div className="odds-heading-row">
                            <div className="program-number"><p>#</p></div>
                            <div className="number-of-picks"><p>Picks</p></div>
                            <div className="horse-name"><p>Horse</p></div>
                            <div className="sok-odds"><p>SoK Odds</p></div>
                          </div>
                          <ol>
                            { race.entries.map((entry, index) =>
                              <span key={entry.id}>
                                <li className="odds-race-entrant-row" onClick={() => togglePicks(entry.id, index)}>
                                  <div className="program-number">
                                    <div className={`saddle-cloth-${entry.program_number && entry.program_number.replace(/\D/g,'')}`}><p>{entry.program_number}</p></div>
                                  </div>
                                  <div className="number-of-picks"><p>{entry.picks_count}</p></div>
                                  <div className="horse-name"><p>{entry.horse.name}</p></div>
                                  <div className="sok-odds"><p>{entry.sok_odds}</p></div>
                                </li>
                                <li id={'entrant' + index + '-player-picks'} className="odds-player-picks" style={{display: 'none'}}>
                                  <div className="players-who-picked">
                                    <p>Players Who Picked <span className="horse-name-color">{entry.horse.name}</span></p>
                                  </div>
                                  <div className="odds-player-picks-heading">
                                    <div className="rank-heading"><p>Rank</p></div>
                                    <div className="player-heading"><p>Player</p></div>
                                    <div className="total-heading"><p>Total</p></div>
                                  </div>
                                {entry.picks &&
                                  <ol>
                                    {entry.picks.map((pick) =>
                                      <li key={pick.id} className="player-row">
                                        <div className="rank"><p>{pick.rank}</p></div>
                                        <div className="player"><p>{pick.handle}</p></div>
                                        <div className="total"><p>${pick.total}</p></div>
                                      </li>
                                    )}
                                  </ol>
                                }
                                </li>
                              </span>
                            )}
                          </ol>
                        </div>
                      </div>
                    )}
                    <div className="make-picks-next-race-row">
                      {race.delta > 1 ?
                        <div className="previous-race" onClick={() => handleRacePager(-1)}>
                          <p><img src="/img/blue-carat-left.svg" alt="Previous Race Button" />Prev Race</p>
                        </div>
                        :
                        <div className="previous-race"></div>
                      }
                      {contest.contest_races && race.delta < contest.contest_races.length &&
                        <div className="next-race" onClick={() => handleRacePager(1)}>
                          <p>Next Race<img src="/img/blue-carat-right.svg" alt="Next Race Button" /></p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

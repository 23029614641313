import React, { useState, useContext } from 'react'
import { Link } from "react-router-dom";
import { UserContext } from './App'
import axios from 'axios';

export default function UserLogin() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { userData, setUserData } = useContext(UserContext)

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleLoginSubmit(event) {
    event.preventDefault();

    axios.post(`${process.env.REACT_APP_API_URL}players/login`, {
      player: {
        email: email,
        password: password
      }
    }).then(res => {
      axios.defaults.headers.common['Authorization'] = "Token " + res.data.player.token
      setUserData({
        loaded: true,
        player: res.data.player,
        token: res.data.player.token
      })
    }).catch(error => {
      let message = 'Login failed.'
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message
      }
      alert(message)
    })
  }

  return (
    <div className="loginform">
      {!userData.token && (
        <div>
          <input id="signin-checkbox" type="checkbox" />
          <div className="signin-container">
            <div className="signin-card">             
              <div className="register1-button">
                <div className="signin-info-container">
                  <div className="horse-w-stars">
                    <img src="/img/horse-w-stars-white-on-black.svg" alt="Sport of Kings Icon" />
                  </div>
                  <div className="signin-close-button">
                    <label htmlFor="signin-checkbox"><img src="/img/close.svg" alt="close" /></label>
                  </div>
                  <div className="signin-title-text">Please sign in...
                  </div>
                    <form className="signin-form" onSubmit={handleLoginSubmit}>
                      <div className="signin-text-field-container">
                        <input type="email" id="player-email" name="player-email" placeholder="Enter email address..." onChange={e => setEmail(e.target.value)} />
                      </div>
                      <div className="signin-text-field-container">
                        <input type="password" id="player-password" name="player-password" placeholder="Enter password..." onChange={e => setPassword(e.target.value)} />
                      </div>
                      <div className="save-changes-container">
                        <input disabled={!validateForm()} type="submit" value="SUBMIT" />
                      </div>
                    </form>
                  <Link className="outlined-button-smaller" to="/password" onClick={() => document.getElementById("signin-checkbox").checked = false}>FORGOT PASSWORD</Link>
                  <Link className="outlined-button-smaller" to="/register" onClick={() => document.getElementById("signin-checkbox").checked = false}>REGISTER</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

import React, { useState, useContext } from 'react'
import { Formik, Form, Field } from 'formik';
import { UserContext } from './App'
import axios from 'axios';

export default function UserPersonalInfo({ handleAccountUpdate }) {
  const { userData, setUserData } = useContext(UserContext)
  const [editMode, setEditMode] = useState(false)
  const [uploadMode, setUploadMode] = useState(false)

  function handleImageUpload(e) {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append(
      'player[image]',
      file,
      file.name
    )
    axios.patch(`${process.env.REACT_APP_API_URL}player`, formData).then(res => {
        setUserData({
          loaded: true,
          player: res.data.player,
          token: res.data.player.token
        })
        setUploadMode(false)
      }).catch(error => {
        alert('Please attach a valid JPG or PNG.')
      })
  }

  return (
    <div className="account-details">             
      <div className="account-wrapper">
        <div className="account-button">
          <div className="account-button-container">
            <div className="account-title-text">Personal Info</div>
            <div className="carat">
              <img src="/img/white-carat-up.svg" alt="" className="changeCarat" />
            </div>
          </div>
        </div>
        <div className="account-reveal">
          <div className="account-picture-container">
            <div className="player-pic-icon">
              { userData.player.image ? (
                <img src={userData.player.image} alt="" />
              ) : (
                <img src="/img/player-picture-icon.svg" alt="" />
              )}
            </div>
          </div>
          <div className="account-picture-container">
            { uploadMode ? (
              <input type="file" onChange={e => handleImageUpload(e)} />
            ) : (
              <div className="outlined-button-smaller" onClick={() => setUploadMode(true)}>
                UPLOAD PHOTO
              </div>
            )}
          </div>
          { editMode ? <UserPersonalInfoEditForm userData={userData} handleAccountUpdate={handleAccountUpdate} setEditMode={setEditMode} /> : (
            <div>
              <div className="account-detail-container-edit">
                <div>
                  <div className="account-page-white-text">Player name:</div>
                  <div className="account-page-text">{userData.player.handle}</div>
                </div>  
                <div>
                  <button className="outlined-button-smaller" onClick={() => setEditMode(true)}>Edit</button>
                </div>
              </div>
              <div className="account-detail-container">
                <div className="account-page-white-text">Real name:</div>
                <div className="account-page-text">{userData.player.first_name} {userData.player.last_name}</div>
              </div>
              <div className="account-detail-container">
                <div className="account-page-white-text">Email address:</div>
                <div className="account-page-text">{userData.player.email}</div>
              </div>
              <div className="account-detail-container">
                <div className="account-page-white-text">Twitter:</div>
                <div className="account-page-text">
                  <a href={`https://twitter.com/${userData.player.twitter_handle}`}>{userData.player.twitter_handle}</a>
                </div>
              </div>
              <div className="account-detail-container">
                <div className="account-page-white-text">Password:</div>
                <div className="account-page-text">*******</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const UserPersonalInfoEditForm = ({ userData, handleAccountUpdate, setEditMode }) => {
  return (
    <Formik
      initialValues={{ 
        handle: userData.player.handle,
        first_name: userData.player.first_name,
        last_name: userData.player.last_name,
        email: userData.player.email,
        twitter_handle: userData.player.twitter_handle,
        password: '',
        password_confirmation: ''
      }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setEditMode(false)
        handleAccountUpdate(values, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="account-text-field-container">
            <div className="prefs-text">
              <label htmlFor="player-name">Player name:</label>
            </div>
            <div className="text-field-container">
              <Field type="text" name="handle" maxlength="18" />
            </div>
          </div>
          <div className="account-text-field-container">
            <div className="prefs-text">
              <label htmlFor="player-first-name">First name:</label>
            </div>
            <div className="text-field-container">
              <Field type="text" name="first_name" />
            </div>
          </div>
          <div className="account-text-field-container">
            <div className="prefs-text">
              <label htmlFor="player-last-name">Last name:</label>
            </div>
            <div className="text-field-container">
              <Field type="text" name="last_name" />
            </div>
          </div>
          <div className="account-text-field-container">
            <div className="prefs-text">
              <label htmlFor="player-email">Email:</label>
            </div>
            <div className="text-field-container">
              <Field type="email" name="email" />
            </div>
          </div>
          <div className="account-text-field-container">
            <div className="prefs-text">
              <label htmlFor="player-twitter-handle">Twitter Handle:</label>
            </div>
            <div className="text-field-container">
              <Field type="text" name="twitter_handle" />
            </div>
          </div>
          <div className="account-text-field-container">
            <div className="prefs-text">
              <label htmlFor="player-password-update">Password:</label>
            </div>
            <div className="text-field-container">
              <Field type="password" name="password" />
            </div>
          </div>
          <div className="account-text-field-container">
            <div className="prefs-text">
              <label htmlFor="player-password-update-confirm">Retype password:</label>
            </div>
            <div className="text-field-container">
              <Field type="password" name="password_confirmation" />
            </div>
          </div>
          <div className="save-changes-container">
            <input type="submit" value="SAVE CHANGES" disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

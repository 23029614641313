import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from './App'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import ContestCard from './ContestCard'
import EnterClick from './EnterClick'
import { useParams } from "react-router-dom";
import axios from 'axios';

export default function Contest() {
  const [contest, setContest] = useState({})
  let { contestId } = useParams();
  const { userData } = useContext(UserContext)

  useEffect(() => {
    if (userData.loaded) {
      axios.get(`${process.env.REACT_APP_API_URL}contests/${contestId}`)
        .then(res => {
          setContest(res.data)
        })
    }
  }, [contestId, userData])

  const handleEnterClick = (contestId) => {
    EnterClick(contestId, function(data) {
      setContest(data)      
    })
  }

  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>{contest.name ? `Sport of Kings | ${contest.name}` : 'Sport of Kings | Contest'}</title>
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="contests-column">
            <div className="contests-title-row">
              <div className="contests-header">CONTESTS</div>
            </div>
            <ol className="contests-container">
              { contest &&
                <ContestCard contest={contest} handleEnterClick={handleEnterClick} />
              }
            </ol>
          </div>
        </main>
      </div>
    </div>
  )
}

import React from 'react'
import { Link } from "react-router-dom";
import Obfuscate from 'react-obfuscate';

export default function SOKFooter() {
  return (
    <footer>
      <div className="footer-outer-wrapper">
        <div className="footer-container">
          <div className="divider-container">
            <div className="rule-left">
              <hr />
            </div>
            <div className="footer-image-container">
              <img className="horse-w-stars" src="/img/horse-w-stars-white-on-black.svg" alt="Footer Horse" />
            </div>
            <div className="rule-right">
              <hr />
            </div>
          </div>


          <div className="footer-grid">
            <div className="sok-tagline">
              <h3>Sport of Kings ®</h3>
              <p>Free contests.<br />Free past performances.<br />Real Prizes.</p>
            </div>
            <div className="get-the-info">
              <h3>About Sport of Kings</h3>
              <p><Link to="/about">About/Mission</Link></p>
              <p><Link to="/benefactors">Benefactors</Link></p>
              <p><a href="https://sport-of-kings.square.site" target="_blank">Store</a></p>
            </div>
            <div className="social">
              <h3>Other Media</h3>
              <p><a href="https://twitter.com/sportofkings20" target="_blank">Follow Us on Twitter</a></p>
              <p><Link to="/podcast">Sport of Kings Podcast</Link></p>
              <p><Link to="/press">Press</Link></p>
            </div>
            <div className="contact">
              <h3>Contact Information</h3>
              <p>
                <Obfuscate email="hello@sportofkings.net" /></p>
            </div>
          </div>
          <div className="copyright">
            <p>© {new Date().getFullYear()} Sport of Kings. All rights reserved. Pick & Pray used with the express permission of HorseTourneys.com</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

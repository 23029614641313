import React, { useState, useEffect, useContext } from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import PicksButton from './PicksButton'
import { Link, useParams } from "react-router-dom";
import { UserContext } from './App'
import axios from 'axios';
import Moment from 'moment';
import Countdown from 'react-countdown';
import EnterClick from './EnterClick'
import { ActionCableConsumer } from 'react-actioncable-provider';
import InfiniteScroll from "react-infinite-scroll-component";
import $ from 'jquery'
import AdSense from 'react-adsense';

export default function Standings() {
  const [contests, setContests] = useState([])
  const [contest, setContest] = useState({})
  const { userData } = useContext(UserContext)
  const [title, setTitle] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [standings, setStandings] = useState([])
  const [standingsTotal, setStandingsTotal] = useState(0)
  const [nextContestRace, setNextContestRace] = useState({ loading: true })
  const [offset, setOffset] = useState(25)
  const [searching, setSearching] = useState(false)
  const [nhcOnly, setNhcOnly] = useState(false)
  const [favoritesOnly, setFavoritesOnly] = useState(false)
  const [recentPickHeading, setRecentPickHeading] = useState('')
  const [noPickText, setNoPickText] = useState('')
  let { contestId } = useParams()

  useEffect(() => {
    if (userData.loaded) {
      refreshContests()
    }
  }, [contestId, userData])

  useEffect(() => {
    setTitle(contest.name)
    if (contest.contest_races) {
      setNextContestRace(contest.contest_races.find(contestRace => contestRace.betting_open))
    }
    resetStandings()
  }, [contest])

  function handleEnterClick(contestId) {
    EnterClick(contestId, function(data) {
      setActiveContest(data)
    })
  }

  function setActiveContest(c) {
    if (contest.ad_banner_url && !c.ad_banner_url) {
      $('#ad-banner-url').slideUp()
      setTimeout(() => {setContest(c)}, 400)
    }
    else if (!contest.ad_banner_url && c.ad_banner_url) {
      setContest(c)
      setTimeout(() => {$('#ad-banner-url').slideDown()}, 400)
    }
    else {
      setContest(c)
    }
    if (!c.contest_races || !c.contest_races.find(contestRace => !contestRace.betting_open)) {
      if (c.contest_type === 'Series') {
        setRecentPickHeading('Most recent contest')
      }
      else {
        setRecentPickHeading('Picks show when 1st race closes...')
      }
      setNoPickText('—')
    }
    else {
      if (c.contest_type == 'Pick N Pray') {
        setRecentPickHeading('All picks')
        setNoPickText('—')
      }
      else {
        setRecentPickHeading('Most recent pick')
        setNoPickText('- No Pick -')
      }
    }
  }

  function toggleActiveContest(contestId) {
    let c = contests.find((cc) => cc.id === contestId)
    setActiveContest(c)
    localStorage.setItem('sportofkings.activeContest', contestId)
    $('#other-standings').slideUp()
    $('#other-standings-carat').attr('src', '/img/white-carat-down.svg');
  }

  function resetStandings(favorites = false) {
    let contestUrl = `${process.env.REACT_APP_API_URL}standings`
    if (contest && contest.id) {
      contestUrl += `/${contest.id}`
      if (favorites) {
        contestUrl += '?favorites=true'
      }
      axios.get(contestUrl)
        .then(res => {
          setStandings(res.data.standings)
          setStandingsTotal(res.data.total)
          setOffset(25)
          setHasMore(res.data.has_more)
        })
    }
  }

  function refreshContests() {
    if (contestId) {
      axios.get(`${process.env.REACT_APP_API_URL}contests/${contestId}`)
        .then(res => {
          setContests([res.data])
          setActiveContest(res.data)
        })
    }
    else {
      axios.get(`${process.env.REACT_APP_API_URL}contests?status=active_not_upcoming&&include=contest_races`)
        .then(res => {
          setContests(res.data)
          let c = {};
          const activeContestId = localStorage.getItem('sportofkings.activeContest')
          if (activeContestId) {
            c = res.data.find((cc) => cc.id === parseInt(activeContestId))
          }
          if (!c || Object.keys(c).length === 0 && c.constructor === Object) {
            c = res.data.filter((cc) => !cc.finished && !cc.upcoming)[0]
          }
          setActiveContest(c)
        })
    }
  }

  function fetchMoreData() {
    let contestUrl = `${process.env.REACT_APP_API_URL}standings`
    if (contest && contest.id) {
      contestUrl += `/${contest.id}?offset=${offset}`
      axios.get(contestUrl)
        .then(res => {
          setStandings(prevStandings => [...prevStandings, ...res.data.standings])
          setOffset(prevOffset => prevOffset + 25)
          setHasMore(res.data.has_more)
        })
    }    
  }

  function toggleTierOne(playerId, standingId, index) {
    // Toggle the first tier of standings.
    // For most contests, this is player picks.
    // The exception is for Series Contests: for those we load the player's
    // performance across prior contests in the series.
    if (standings[index].picks) {
      $('#entrant' + index + '-player-picks').slideUp()
      setStandings(standings.map((s, i) => {
        if (s && s.id === standingId) {
          s.picks = null
        }
        return s;
      }))
    }
    else {
      if (contest.contest_races.find(contestRace => !contestRace.betting_open) || contest.contest_type === 'Series') {
        let picksUrl = process.env.REACT_APP_API_URL
        if (contest.contest_type === 'Series') {
          picksUrl += `series_contests?player_id=${playerId}&contest_id=${contest.id}`
        }
        else {
          picksUrl += `picks?player_id=${playerId}&contest_id=${contest.id}`
        }
        axios.get(picksUrl)
          .then(res => {
            setStandings(standings.map((s, i) => {
              if (s && s.id === standingId) {
                s.picks = res.data
              }
              return s
            }))
            $('#entrant' + index + '-player-picks').slideDown()
          })
      }
      else {
        $('#entrant' + index + '-player-picks').slideToggle()
      }
    }
  }

  function toggleTierTwo(entityId, index, pickIndex, playerId, contest) {
    // Toggle the second tier of standings.
    // For most contests, this is race results.
    // The exception is for Series Contests: for those we load the player's
    // picks for the selected contest.
    if (standings[index].picks[pickIndex].results) {
      $('#pick' + index + '-' + pickIndex + '-results').slideUp()
      setStandings(standings.map((s, i) => {
        if (i === index) {
          s.picks = s.picks.map((p, j) => {
            if (j === pickIndex) {
              p.results = null
            }
            return p
          })
        }
        return s;
      }))
    }
    else {
      let resultsUrl = process.env.REACT_APP_API_URL
      if (contest.contest_type === 'Series') {
        resultsUrl += `picks?player_id=${playerId}&contest_id=${entityId}`
      }
      else {
        resultsUrl += `results?race_id=${entityId}`
      }
      axios.get(resultsUrl)
        .then(res => {
          console.log(res.data)
          setStandings(standings.map((s, i) => {
            if (i === index) {
              s.picks = s.picks.map((p, j) => {
                if (j === pickIndex) {
                  p.results = res.data
                }
                return p
              })
            }
            return s
          }))
          $('#pick' + index + '-' + pickIndex + '-results').slideDown()
        })
    }
  }

  function handleSearch(e) {
    if (e.key === 'Enter') {
      let q = e.target.value
      if (q.length >= 3) {
        let searchUrl = `${process.env.REACT_APP_API_URL}standings/${contest.id}/search?q=${q}`
        axios.get(searchUrl)
          .then(res => {
            setStandings(res.data.standings)
            setOffset(25)
            setHasMore(res.data.has_more)
            setSearching(true)
          })
      }
    }
  }

  function checkClearSearch(e) {
    if (searching && !e.target.value.length) {
      resetStandings()
      setSearching(false)
    }
  }

  function toggleMoreStandings() {
    if (contests.length > 1) {
      if ($('#other-standings-carat').attr('src') === '/img/white-carat-down.svg') {
        $('#other-standings-carat').attr('src', '/img/white-carat-up.svg');
      }
      else {
        $('#other-standings-carat').attr('src', '/img/white-carat-down.svg');
      }
      $('#other-standings').slideToggle()
    }
  }

  function toggleNhcOnly() {
    if (nhcOnly) {
      setNhcOnly(false)
    }
    else {
      setNhcOnly(true)
      window.scrollTo(window.scrollX, window.scrollY + 1)
    }
  }

  function toggleFavoritesOnly() {
    if (userData.loaded && !userData.token) {
      alert('You must sign in to favorite other players.')
    }
    else {
      resetStandings(!favoritesOnly)
      setFavoritesOnly(!favoritesOnly)
    }
  }

  function toggleFavorite(e, player_id) {
    e.stopPropagation()
    // todo error if not signed in
    if (userData.loaded && !userData.token) {
      alert('You must sign in to favorite other players.')
    }
    else {
      axios.post(`${process.env.REACT_APP_API_URL}favorite/${player_id}`)
        .then(res => {
          setStandings(standings.map((s, i) => {
            if (s && s.player_id === player_id) {
              s.favorite = !s.favorite
            }
            return s
          }))
        })
        .catch(error => {
          alert('Error updating favorite.  Please try again.')
        })
    }
  }

  function lockPicks() {
    setRecentPickHeading('Locking down picks...')
    setTimeout(() => {
      setRecentPickHeading('Loading picks...')
    }, 5000)
  }

  function handleReceivedStandings(response) {
    console.log('Standings update received!')
    setNextContestRace(response.next_contest_race)
    refreshContests()
    // resetStandings()
    if (contest.contest_type == 'Pick N Pray') {
      setRecentPickHeading('All picks')
      setNoPickText('—')
    }
    else {
      setRecentPickHeading('Most recent pick')
      setNoPickText('- No Pick -')
    }
  }

  return (
    <div className="outer-wrapper">
      <div className="content-wrapper">
        <main>
          <SOKSidebar contests={contests} />
          <div className="standings">
            <div id="ad-banner-url" className="standings-advertisement">
              { contest && contest.ad_banner_url && 
                <a href={contest.ad_destination_url}>
                  <img src={contest.ad_banner_url} />
                </a>
              }
            </div>
            { !contest.invitation_only && <>
              <div className="standings-google-adsense">
                <AdSense.Google
                  client='ca-pub-3879521305455159'
                  slot='9392910106'
                  style={{ width: 729, height: 90 }}
                  format=''
                />
              </div>
              <div className="standings-google-adsense-701-886">
                <AdSense.Google
                  client='ca-pub-3879521305455159'
                  slot='9392910106'
                  style={{ width: 468, height: 60 }}
                  format=''
                />
              </div>
              <div className="standings-google-adsense-700-or-less">
                <AdSense.Google
                  client='ca-pub-3879521305455159'
                  slot='9392910106'
                  style={{ width: 300, height: 50 }}
                  format=''
                />
              </div>
            </> }
            <div className="standings-container">
              <div className="contest-title-row">
                { contests.length ? (
                  <div>
                    <div className="standings-header" onClick={toggleMoreStandings}>
                      {title}
                      { contests.length > 1 &&
                        <img src="/img/white-carat-down.svg" className="changeCarat" id="other-standings-carat" alt="Select Different Standings Button" />
                      }
                    </div>
                    <div className="more-standings" id="other-standings">
                      { contests.filter((c) => c.id != contest.id && !c.finished && !c.upcoming).sort((a, b) => {
                        return new Date(b.start_date) - new Date(a.start_date)
                      }).map((c) =>
                        <div key={c.id} className="standings-header" onClick={() => toggleActiveContest(c.id)}>{c.name}</div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="standings-header">{title}</div>
                )}
                <div className="gradient-rule"></div>
              </div>
              { contest && contest.id && (
                <div className="outlined-red-buttons-row">
                  <Helmet>
                    <title>{contest.name ? `Sport of Kings | ${contest.name} Standings` : 'Sport of Kings | Standings'}</title>
                    <meta name="description" content="Click name of contest to see standings for other active contests." />
                  </Helmet>
                  <ActionCableConsumer
                    channel={{ channel: 'StandingsChannel', contest_id: contest.id }} onReceived={handleReceivedStandings}
                  />
                  <PicksButton contest={contest} handleEnterClick={handleEnterClick} className='outlined-button-make-picks' />
                  <div className="outlined-button-player-search">
                    <input type="search" onChange={checkClearSearch} onKeyDown={handleSearch} placeholder="Player search..." />
                  </div>
                  <StandingsCountdown lockPicks={lockPicks} contest={contest} nextContestRace={nextContestRace} />
                </div>
              )}
              <div className="player-heading-row">
                <div className="rank-heading"><p>Rank</p></div>
                <div className="player-heading"><p><span className="darken-text">Player</span> <span id="nhcToggle" className="nhc-toggle" onClick={toggleNhcOnly}>{ (nhcOnly) ? '(All)' : '(NHC Only)' }</span><img className={favoritesOnly ? 'horse-favorite-icon horse-favorite-activated' : 'horse-favorite-icon'} src="/img/purple-horse-dark.svg" alt="Horse Favorite" onClick={toggleFavoritesOnly} /> <span className="ital-text">({standingsTotal}&nbsp;entries)</span></p></div>
                <div className="total-heading"><p>Total</p></div>
                <div className="recent-pick-heading"><p>{recentPickHeading}</p></div>
              </div>
              <InfiniteScroll
                dataLength={standings.length}
                next={fetchMoreData}
                hasMore={hasMore}
              >
              { standings.filter((standing) => (nhcOnly) ? standing.nhc !== null : standing !== null ).map((standing, index) => 
                <div key={index}>
                  <div onClick={() => toggleTierOne(standing.player_id, standing.id, index)} className={standing.current_player ? 'logged-in-player-row player-row' : 'player-row'} key={standing.id}>
                    <div className="rank"><p>{ nhcOnly ? standing.nhc_rank : standing.rank }</p></div>
                    <div className="player"><p>
                      { standing.handle }
                      <NHCIcon standing={standing} />
                      { !standing.current_player && 
                        <img className={ standing.favorite ? 'horse-favorite-icon horse-favorite-activated' : 'horse-favorite-icon'} src="/img/purple-horse-dark.svg" alt="Horse Favorite" onClick={(e) => toggleFavorite(e, standing.player_id)} />
                      }
                    </p></div>
                    <div className="total"><p>${ parseFloat(standing.total).toFixed(2) }</p></div>
                    <div className="recent-pick"><p><PickDetail contestType={contest.contest_type} standing={standing} noPickText={noPickText} /></p></div>
                  </div>
                    <ol id={'entrant' + index + '-player-picks'} className="contest-picks" style={{display: 'none'}}>
                      { standing.picks ? standing.picks.map((pick, pickIndex) =>
                        <PickDetailRow key={pickIndex} pick={pick} pickIndex={pickIndex} index={index} toggleTierTwo={toggleTierTwo} contest={contest} playerId={standing.player_id} />
                      ) : (contest.contest_races.find(contestRace => !contestRace.betting_open) || contest.contest_type === 'Series' ) ? '' : (
                        <div className="picks-row">
                          <div className="race-number"></div>
                          <div className="race1-pick">Results will be here.</div>
                        </div>
                      )}
                    </ol>
                </div>
              )}
              </InfiniteScroll>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

const StandingsCountdown = ({contest, nextContestRace, lockPicks}) => {
  if (nextContestRace && nextContestRace.race) {
    return <div className="outlined-button-race-timer">
      <span className="timertip">Next race is {Moment(nextContestRace.race.race_starts_at).format('MMM D')},<br /> {nextContestRace.race.short_name}.
      </span>
      <div className="white-timer-text">Next&nbsp;race&nbsp;closes: </div>
      <div className="timer">
        <Countdown
          key={contest.contest_type == 'Pick N Pray' ? contest.betting_closes_at : nextContestRace.race.betting_closes_at}
          date={contest.contest_type == 'Pick N Pray' ? contest.betting_closes_at : nextContestRace.race.betting_closes_at}
          daysInHours={true}
          onComplete={lockPicks}
        />
      </div>
    </div>
  }
  else if (nextContestRace && nextContestRace.loading) {
    return <div className="outlined-button-race-timer">
      <div className="white-timer-text">Loading...</div>
    </div>
  }
  else if (contest.races_open) {
    return <div className="outlined-button-race-timer">
      <div className="white-timer-text">Awaiting Results...</div>
    </div>
  }
  else if (new Date(contest.end_date).getTime() >= new Date().getTime()) {
    return <div className="outlined-button-race-timer">
      <div className="white-timer-text">More races to come...</div>
    </div>
  }
  else {
    return <div className="outlined-button-race-timer">
      <div className="white-timer-text">Contest Finished</div>
    </div>
  }
}

const NHCIcon = ({standing}) => {
  let iconButton = ''
  if (standing.nhc === 0) {
    iconButton = <img className="nhc-tour-logo" src="/img/nhc0.svg" alt="NHC Tour Logo" />
  }
  else if (standing.nhc === 1) {
    iconButton = <img className="nhc-qualify1" src="/img/nhc1.svg" alt="NHC Single Qualify Icon" />
  }
  else if (standing.nhc === 2) {
    iconButton = <img className="nhc-qualify2" src="/img/nhc2.svg" alt="NHC Double Qualify Icon" />
  }
  return iconButton
}

const PickDetail = ({contestType, standing, noPickText}) => {
  if (contestType === 'Pick N Pray' && standing && standing.latest_pick_name && standing.latest_pick_name.includes('|')) {
    return standing.latest_pick_name.split('|').map((race) => {
      const raceStatus = race.split(':')[0]
      const programNumber = race.split(':')[1]
      const saddleClothClass = (programNumber == 'X') ? 'x' : programNumber.replace(/\D/g,'')
      return <div className={`saddle-cloth-${saddleClothClass}`} style={ { float: 'left' }}>{programNumber}</div>
    })
  }
  else {
    return standing.latest_pick_name || noPickText
  }
}

const PickDetailRow = ({pick, pickIndex, index, toggleTierTwo, contest, playerId}) => {
  return <div>
    <div className="picks-row" onClick={() => toggleTierTwo(pick.entity_id, index, pickIndex, playerId, contest)}>
      <div className="race-number"><p><span className="race-date">{pick.race_date}</span><br />{pick.track_name} {pick.race_number}</p></div>
      <div className="race1-pick"><p>{pick.horse}</p></div>
      { pick.race_completed ?
        <div className="win-place-price">
          { contest.contest_type !== 'Series' && 
            <div className="win-price"><p>
              <span className="money-color">$</span>{pick.payout_win}</p>
            </div>
          }
          { contest.contest_type !== 'Series' && 
            <div className="place-price"><p>
              <span className="money-color">$</span>{pick.payout_place}</p>
            </div>
          }
          <div className="total-price"><p>
            <span className="money-color">$</span>{pick.payout_total}</p>
          </div>
        </div>
        :
        <div className="money-color">Results Pending...</div>
      }
    </div>
    <div className="race-results-wrapper" id={'pick' + index + '-' + pickIndex + '-results'} style={{display: 'none'}}>
      { contest.contest_type === 'Series' ? (
        <ol className="race-results">
          { pick.results && pick.results.map((contestPick, contestPickIndex) =>
            <div className="results-row">
              <div className="results-horse-number"><p>{contestPick.race_date} {contestPick.track_name} {contestPick.race_number}</p></div>
                <div className="horse-placed-first">{contestPick.horse}</div>
                { contestPick.race_completed ?
                  <div className="results-wps-price">
                    <div className="win-price"><p>${contestPick.payout_win}</p></div> 
                    <div className="place-price"><p>${contestPick.payout_place}</p></div>
                    <div className="show-price"><p>${contestPick.payout_total}</p></div>
                  </div>
                :
                <div className="money-color">Results Pending...</div>
              }
            </div>
          )}
        </ol>
      ) : (
        pick.results && pick.results.winners.length > 0 && (
          <ol className="race-results">
            <div className="results-row-header">
              <div className="results-race-number"><p>{pick.track_name} {pick.race_number}</p></div>
              <div className="results-header"><p>RESULTS</p></div>
              <div className="results-wps-price">
                <div className="header-win-price"><p>WIN</p></div> 
                <div className="header-place-price"><p>PLACE</p></div>
                <div className="header-show-price"><p>SHOW</p></div>
              </div>
            </div>
            { pick.results.winners.map((winner) =>
              <div className="results-row">
                <div className="results-horse-number"><div className={`saddle-cloth-${winner.program_number && winner.program_number.replace(/\D/g,'')}`}><p>{winner.program_number}</p></div></div>
                <div className="horse-placed-first"><Link to={`/contests/${contest.id}/odds?race=${pick.entity_id}&entry=${winner.entry_id}`}>{winner.name}</Link></div>
                <div className="results-wps-price">
                  <div className="win-price"><p>{(winner.payout_win > 0) ? '$' + winner.payout_win : ''}</p></div> 
                  <div className="place-price"><p>{(winner.payout_place > 0) ? '$' + winner.payout_place : ''}</p></div>
                  <div className="show-price"><p>${winner.payout_show}</p></div>
                </div>
              </div>
            )}
            { pick.results.scratches && (
              <div className="results-row-scratches">
                <div className="results-horse-number"><p></p></div>
                <div className="scratches-word"><p>Scratches:</p></div>
                <div className="scratches"><p>
                  { pick.results.scratches.map((scratch) =>
                    <span>
                      {scratch.program_number + " - " + scratch.name}
                      <br />
                    </span>
                  )}
                </p></div>
              </div>
            )}
          </ol>
        )
      )}
    </div>
  </div>
}

import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Moment from 'moment';
import moment from 'moment-timezone';
import Countdown from 'react-countdown';
import ReactGA from 'react-ga';
import axios from 'axios';
import $ from 'jquery'

export default function ContestEntryCard({contest, contestIndex, destination, mode = 'post_time'}) {

  useEffect(() => {
    $('.contest-button').off('click');
    $('.contest-button').on('click', function(e) {
      if ($(this).find('.changeCarat').attr('src') === '/img/white-carat-down.svg') {
        $(this).find('.changeCarat').attr('src', '/img/white-carat-up.svg');
      }
      else {
        $(this).find('.changeCarat').attr('src', '/img/white-carat-down.svg');
      }
      $(this).parent().next('.contest-reveal').slideToggle();
    });
  }, [])

  return (
    <div className="make-picks-container">
      <div className="contest-details">
        <div className="contest-title">
          <div className="contest-button">
            <div className="contest-button-container">
              <div className="contest-title-text">{ contest.name }</div>
              <div className="carat">
                <img src="/img/white-carat-down.svg" alt="" className="changeCarat" />
              </div>
            </div>
          </div>
        </div>
        <div className="contest-reveal">
          <div className="race-selection-container">
            <ol>
              <li className="race-selection-row-header">                          
                <div className="race-event-number-header"><p>Event #</p></div>
                <div className="race-event-details-header">
                  <p>Track/Race <br /><span className="gray-text">(date)</span></p>
                </div>
                <div className="race-event-time-header">
                  <p>Post time</p>
                </div>
                <div className="race-picks-deadline-header">Picks Deadline 
                </div>
              </li>
              { contest.contest_races.length > 0 ? (
                contest.contest_races.slice(-15).map((contestRace) =>
                  mode === 'pps' ? (
                    <RaceSelectionRow contest={contest} contestRace={contestRace} destination={destination} key={contestRace.id} mode={mode} />
                  ) : (
                    <Link to={`/contests/${contest.id}/${destination}?race=${contestRace.race.id}`}>
                      <RaceSelectionRow contest={contest} contestRace={contestRace} destination={destination} key={contestRace.id} mode={mode} />
                    </Link>
                  )
                )
              ) : (
                <h3>Races will show a few days before start of contest.</h3>
              )}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

const RaceSelectionRow = ({contest, contestRace, destination, mode}) => {
  function trackPPClick(race) {
    axios.post(`${process.env.REACT_APP_API_URL}pps/${race.id}`)
    ReactGA.event({
      category: 'PPs',
      action: race.pp_format,
      label: race.name
    });
  }

  return (
    <li className="race-selection-row" key={contestRace.id}>
      <div className="race-event-number"><p>Event {contestRace.delta}</p></div>
      <div className="race-event-details">
        <p>{ contestRace.race.short_name } <span className="gray-text">({ Moment(contestRace.race.race_starts_at).format('M/D') })</span></p>
      </div>
      { mode === 'pps' ? (
        <div className="race-event-time">
          { contestRace.race.pp_url && contestRace.race.pp_format && contestRace.race.pp_format != 'ComingSoon' ? (
            <a href={contestRace.race.pp_url} onClick={() => trackPPClick(contestRace.race)} target="_blank">
              <img src={`/img/drf-${contestRace.race.pp_format.toLowerCase()}-pps.png`} className="regular-pps" />
              <img src="/img/drf-mobile-pps.png" className="mobile-pps" />
            </a>
          ) : (
            <span>PPs Coming Soon!</span>
          )}
        </div>
      ) : (
        <>
          <div className="race-event-time">
            <p><span className="post-time-text">Post time: </span>{Moment(contestRace.race.race_starts_at).tz(moment.tz.guess()).format('h:mma z')}</p>
          </div>
          { contestRace.betting_open ? (
            <div className="race-picks-deadline">
              <span className="picks-deadline-text">Picks Deadline: </span>
              <Countdown
                key={contest.contest_type == 'Pick N Pray' ? contest.betting_closes_at : contestRace.race.betting_closes_at}
                date={contest.contest_type == 'Pick N Pray' ? contest.betting_closes_at : contestRace.race.betting_closes_at}
                daysInHours={true}
              />
            </div>
          ) : (
            <div className="race-picks-deadline">
              <span>Picks Closed</span>
            </div>
          )}
          <div className="race-carat-right">
            <img src="/img/blue-carat-right.svg" alt="Choose race arrow" className="race-selection-right-carat-blue" />
          </div>
        </>
      )}
    </li>
  )
}

import React, { useState, useContext, useEffect } from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import DatePickerField from './DatePickerField'
import UserPersonalInfo from './UserPersonalInfo'
import UserEmailPreferences from './UserEmailPreferences'
import CountryOptions from './CountryOptions'
import StateProvinceOptions from './StateProvinceOptions'
import { Link } from "react-router-dom";
import { UserContext } from './App'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import $ from 'jquery'

export default function UserAccount() {
  const { userData, setUserData } = useContext(UserContext)

  useEffect(() => {
    $('.account-button').off('click');
    $('.account-button').on('click', function(e) {
      if ($(this).find('.changeCarat').attr('src') === 'img/white-carat-down.svg') {
        $(this).find('.changeCarat').attr('src', 'img/white-carat-up.svg');
      }
      else {
        $(this).find('.changeCarat').attr('src', 'img/white-carat-down.svg');
      }
      $(this).next('.account-reveal').slideToggle();
    });
  }, [userData])

  function handleAccountUpdate(values, setSubmitting) {
    axios.patch(`${process.env.REACT_APP_API_URL}player`, {
      player: values
    }).then(res => {
      setUserData({
        loaded: true,
        player: res.data.player,
        token: res.data.player.token
      })
      setSubmitting(false)
    }).catch(error => {
      setSubmitting(false)
      alert('Update failed.') // @todo show specific error message, if possible
    })
  }

  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Account | Sport of Kings</title>
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          {userData.token && (
            <div className="contests-column">
              <div className="contests-title-row">
                <div className="contests-header">MY ACCOUNT</div>
              </div>
              <div className="account-intro-text">
                <Link to="/register/wizard">Certify Account!</Link>
              </div>

              <div className="account-container">
                <UserPersonalInfo handleAccountUpdate={handleAccountUpdate} />
                { !userData.player.hibernate &&
                  <UserEmailPreferences userData={userData} handleAccountUpdate={handleAccountUpdate} />
                }
                <UserNHCTour userData={userData} handleAccountUpdate={handleAccountUpdate} />
                <UserHibernate userData={userData} handleAccountUpdate={handleAccountUpdate} />
                <UserBio userData={userData} handleAccountUpdate={handleAccountUpdate} />
                <UserAddress userData={userData} handleAccountUpdate={handleAccountUpdate} />
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  )
}

const UserNHCTour = ({ userData, handleAccountUpdate }) => {
  return (
    <div className="account-details">             
      <div className="account-wrapper">
        <div className="account-button">
          <div className="account-button-container">
            <div className="account-title-text">NHC Tour/Qualifier Status</div>
            <div className="carat">
              <img src="img/white-carat-down.svg" alt="" className="changeCarat" />
            </div>
          </div>
        </div>
        <div className="account-reveal" style={{ display: 'none' }}>
          <Formik
            initialValues={{ 
              nhc_tour_number: userData.player.nhc_tour_number,
              nhc_single_qualified: userData.player.nhc_single_qualified,
              nhc_double_qualified: userData.player.nhc_double_qualified
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleAccountUpdate(values, setSubmitting)
              alert('Changes saved.')
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="account-text-field-container">
                  <div className="prefs-text">
                    <label htmlFor="nhc-tour-number">Your NHC Tour Number</label>
                  </div>
                  <div className="registration-text-field-container">
                    <Field type="text" id="nhc-tour-number" name="nhc_tour_number" placeholder="4- to 6-digit number" maxLength={6} />
                  </div>
                </div>
                <div className="email-prefs-container">
                  <div className="email-prefs-checkbox">
                    <Field type="checkbox" id="nhc_single_qualified" name="nhc_single_qualified" className="larger-checkbox" />
                    <label htmlFor="nhc_single_qualified"></label>
                  </div>
                  <div className="account-page-white-text">Check here if you have qualified for the NHC this year (for next year&rsquo;s NHC).</div>
                </div>
                <div className="email-prefs-container">
                  <div className="email-prefs-checkbox">
                    <Field type="checkbox" id="nhc_double_qualified" name="nhc_double_qualified" className="larger-checkbox" />
                    <label htmlFor="nhc_double_qualified"></label>
                  </div>
                  <div className="account-page-white-text">Check here if you have DOUBLE-qualified for the NHC.</div>
                </div>
                <div className="save-changes-container">
                  <input type="submit" value="SAVE CHANGES" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

const UserHibernate = ({ userData, handleAccountUpdate }) => {
  return (
    <div className="account-details">             
      <div className="account-wrapper">
        <div className="account-button">
          <div className="account-button-container">
            <div className="account-title-text">Account Hibernation</div>
            <div className="carat">
              <img src="img/white-carat-down.svg" alt="" className="changeCarat" />
            </div>
          </div>
        </div>
        <div className="account-reveal" style={{ display: 'none' }}>
          <Formik
            initialValues={{ 
              hibernate: userData.player.hibernate,
              hibernate_until: userData.player.hibernate_until
            }}
            validate={values => {
              const errors = {};
              if (values.hibernate && !values.hibernate_until) {
                errors.hibernate_until = 'Required';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleAccountUpdate(values, setSubmitting)
              alert('Changes saved.')
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <div className="email-prefs-container">
                  <div className="email-prefs-checkbox">
                    <Field type="checkbox" id="hibernate" name="hibernate" className="larger-checkbox" />
                    <label htmlFor="hibernate"></label>
                  </div>
                  <div className="account-page-white-text">Check here if you would like to put your account to bed for now. You will not receive any emails from us during this time. But all of your information will be stored for when you come out of hibernation.</div>
                </div>
                { values.hibernate &&
                  <div className="email-prefs-container">
                    <label htmlFor="hibernate_until">Hibernate Until...</label>
                    <DatePickerField name="hibernate_until" dateFormat='yyyy-MM-dd' />
                    <ErrorMessage name="hibernate_until" component="div" />
                  </div>
                }
                <div className="save-changes-container">
                  <input type="submit" value="SAVE CHANGES" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

const UserContestHistory = ({ userData }) => {
  const [contestHistory, setContestHistory] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}contest-history?rank_only=true`)
      .then(res => {
        setContestHistory(res.data)
      })
  }, [userData])

  return (
    <div className="account-container">
      <div className="account-details">
        <div className="account-wrapper">
          <div className="account-button">
            <div className="account-button-container">
              <div className="account-title-text">Contest History</div>
              <div className="carat">
                <img src="img/white-carat-down.svg" alt="" className="changeCarat" />
              </div>
            </div>
          </div>
          <div className="account-reveal" style={{ display: 'none' }}>
            <div className="account-detail-container">
              <div className="account-page-white-text">Entries won for future contests:</div>
              <div className="account-page-text">NHC Qualifier Nov 6&ndash;7</div>
              <div className="account-page-text">Race of the Day Finals Nov 9&ndash;15</div>
            </div>
            <div className="account-detail-container">
              <div className="account-page-white-text">Best Contest finishes</div>
              { contestHistory.map(record => 
                <div className="account-page-text"><span className="white-text-no-size-change">{record.count }</span> {record.text}</div>
              )}
            </div>
            <div className="account-detail-container">
              <div className="account-page-white-text">See every contest you&rsquo;ve ever played in:</div>
              <div className="account-page-text">Complete Contest History</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const UserPartnerPreferences = ({ userData, handleAccountUpdate }) => {
  return (
    <div className="account-details">             
      <div className="account-wrapper">
        <div className="account-button">
          <div className="account-button-container">
            <div className="account-title-text">Partner Contact Preferences</div>
            <div className="carat">
              <img src="img/white-carat-down.svg" alt="" className="changeCarat" />
            </div>
          </div>
        </div>
        <div className="account-reveal" style={{ display: 'none' }}>
          <form action="#">
            <div className="account-text-field-container">
              <div className="prefs-text">
                <label htmlFor="nhc-tour-number">By default we do not allow our partners to email you directly. But if you check any of boxes below, we&rsquo;ll give them the all clear!</label>
              </div>
              <div className="registration-text-field-container">
                &nbsp;
              </div>
            </div>
            <div className="email-prefs-container">
              <div className="email-prefs-checkbox"><input type="checkbox" id="partner-drf" className="larger-checkbox" unchecked value="partner-drf" /><label htmlFor="partner-drf"></label>
              </div>
              <div className="account-page-text">Daily Racing Form</div>
            </div>
            <div className="email-prefs-container">
              <div className="email-prefs-checkbox"><input type="checkbox" id="partner-abr" className="larger-checkbox" unchecked value="partner-abr" /><label htmlFor="partner-abr"></label></div>
              <div className="account-page-text">America&rsquo;s Best Racing</div>
            </div>
            <div className="email-prefs-container">
              <div className="email-prefs-checkbox"><input type="checkbox" id="partner-twinspires" className="larger-checkbox" unchecked value="partner-twinspires" /><label htmlFor="partner-twinspires"></label></div>
              <div className="account-page-text">TwinSpires.com</div>
            </div>
            <div className="email-prefs-container">
              <div className="email-prefs-checkbox"><input type="checkbox" id="partner-keeneland" className="larger-checkbox" unchecked value="partner-keeneland" /><label htmlFor="partner-keeneland"></label></div>
              <div className="account-page-text">Keeneland Racetrack</div>
            </div>
            <div className="email-prefs-container">
              <div className="email-prefs-checkbox"><input type="checkbox" id="partner-monmouth" className="larger-checkbox" unchecked value="partner-monmouth" /><label htmlFor="partner-monmouth"></label></div>
              <div className="account-page-text">Monmouth Racetrack</div>
            </div>
            <div className="email-prefs-container">
              <div className="email-prefs-checkbox"><input type="checkbox" id="partner-tif" className="larger-checkbox" unchecked value="partner-tif" /><label htmlFor="partner-tif"></label></div>
              <div className="account-page-text">Thoroughbred Idea Foundation</div>
            </div>
            <div className="save-changes-container">
              <input type="submit" value="SAVE CHANGES" />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const UserBio = ({ userData, handleAccountUpdate }) => {
  return (
    <div className="account-details">             
      <div className="account-wrapper">
        <div className="account-button">
          <div className="account-button-container">
            <div className="account-title-text">Your Horse Racing Bio</div>
            <div className="carat">
              <img src="img/white-carat-down.svg" alt="" className="changeCarat" />
            </div>
          </div>
        </div>
        <div className="account-reveal" style={{ display: 'none' }}>
          <Formik
            initialValues={{ 
              how_introduced: userData.player.how_introduced,
              biggest_score: userData.player.biggest_score,
              handicapping_tool: userData.player.handicapping_tool,
              handicapping_angle: userData.player.handicapping_angle
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleAccountUpdate(values, setSubmitting)
              alert('Changes saved.')
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="account-text-field-container">
                  <div className="account-intro-title-text">
                    <p>Tell us, and the community, a bit about your horse racing experience! Boxes are expandable.</p>
                  </div>
                  <div className="registration-text-field-container">
                    &nbsp;
                  </div>
                  <div className="prefs-text">
                    <label htmlFor="intro-horse-racing">How were you introduced to horse racing?</label>
                  </div>
                  <div className="registration-text-field-container">
                    <Field as="textarea" id="how_introduced" name="how_introduced" />
                  </div>
                  <div className="prefs-text">
                    <label htmlFor="intro-horse-racing">What was your biggest score?</label>
                  </div>
                  <div className="registration-text-field-container">
                    <Field as="textarea" id="biggest_score" name="biggest_score" />
                  </div>
                  <div className="prefs-text">
                    <label htmlFor="intro-horse-racing">What is your main handicapping tool? (DRF, Brisnet, Sheets, Optix, etc)</label>
                  </div>
                  <div className="registration-text-field-container">
                    <Field as="textarea" id="handicapping_tool" name="handicapping_tool" />
                  </div>
                  <div className="prefs-text">
                    <label htmlFor="intro-horse-racing">What is your main handicapping angle? (speed, class, pace, etc)</label>
                  </div>
                  <div className="registration-text-field-container">
                    <Field as="textarea" id="handicapping_angle" name="handicapping_angle" />
                  </div>
                </div>
                <div className="save-changes-container">
                  <input type="submit" value="SAVE CHANGES" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

const UserAddress = ({ userData, handleAccountUpdate }) => {
  return (
    <div className="account-details">             
      <div className="account-wrapper">
        <div className="account-button">
          <div className="account-button-container">
            <div className="account-title-text">Mailing Address</div>
            <div className="carat">
              <img src="img/white-carat-down.svg" alt="" className="changeCarat" />
            </div>
          </div>
        </div>
        <div className="account-reveal" style={{ display: 'none' }}>
          <Formik
            initialValues={{ 
              address1: userData.player.address1,
              address2: userData.player.address2,
              city: userData.player.city,
              state: userData.player.state,
              postal_code: userData.player.postal_code,
              country: userData.player.country,
              phone: userData.player.phone
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleAccountUpdate(values, setSubmitting)
              alert('Changes saved.')
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <div className="account-text-field-container">
                  <div className="prefs-text">
                    <label htmlFor="country">Country</label>
                  </div>
                  <div className="text-field-container">
                    <Field name="country" as="select" id="country" className="country-menu">
                      <CountryOptions />
                    </Field>
                  </div>
                </div>
                <div className="account-text-field-container">
                  <div className="prefs-text">
                    <label htmlFor="address-line-one">Address Line 1</label>
                  </div>
                  <div className="text-field-container">
                    <Field type="text" id="address1" name="address1" />
                  </div>
                </div>
                <div className="account-text-field-container">
                  <div className="prefs-text">
                    <label htmlFor="address-line-two">Address Line 2</label>
                  </div>
                  <div className="text-field-container">
                    <Field type="text" id="address2" name="address2" />
                  </div>
                </div>
                <div className="account-text-field-container">
                  <div className="prefs-text">
                    <label htmlFor="address-city">City</label>
                  </div>
                  <div className="text-field-container">
                    <Field type="text" id="city" name="city" />
                  </div>
                </div>
                <div className="account-text-field-container">
                  <div className="prefs-text">
                    <label htmlFor="state">State</label>
                  </div>
                  <div className="text-field-container">
                    <Field name="state" as="select" id="state" className="state-menu">
                      <StateProvinceOptions country={values.country} />
                    </Field>
                  </div>
                </div>
                <div className="account-text-field-container">
                  <div className="prefs-text">
                    <label htmlFor="postal_code">Zip/Postal Code</label>
                  </div>
                  <div className="text-field-container">
                    <Field type="text" id="postal_code" name="postal_code" />
                  </div>
                </div>
                <div className="account-text-field-container">
                  <div className="prefs-text">
                    <label htmlFor="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}">Telephone</label>
                  </div>
                  <div className="text-field-container">
                    <Field type="text" id="phone" name="phone" />
                  </div>
                </div>
                <div className="save-changes-container">
                  <input type="submit" value="SAVE CHANGES" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from './App'
import { Link } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import SOKSidebar from './SOKSidebar'
import axios from 'axios';

export default function UserPasswordConfirm() {
  const [validToken, setValidToken] = useState(false)
  const [reset, setReset] = useState(false)
  const { setUserData } = useContext(UserContext)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    let token = query.get('token')
    axios.get(`${process.env.REACT_APP_API_URL}reset_password?token=${token}`)
      .then(res => {
        setUserData({
          loaded: true,
          player: res.data.player,
          token: res.data.player.token
        })
        setValidToken(true)
      })
      .catch(error => {
        setValidToken(false)
      })
  }, [setUserData])

  function handleSubmit(values, setSubmitting) {
    axios.patch(`${process.env.REACT_APP_API_URL}player`, {
      player: values
    }).then(res => {
      setSubmitting(false)
      setReset(true)
    }).catch(error => {
      setSubmitting(false)
    })
  }

  return (!validToken ? (
    <div className="outer-wrapper">
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="registration-account-column">
            <div className="contests-title-row">
              <div className="contests-header">Invalid Token!</div>
            </div>
          </div>
        </main>
      </div>
    </div>
    ) : (
    <div className="outer-wrapper">
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="registration-account-column">
            <div className="contests-title-row">
              <div className="contests-header">Set new password</div>
            </div>
            { !reset ? (
              <div className="registration-container">
                <div className="registration-details">
                  <div className="register1-button">
                    <div className="registration-info-container">
                      <div className="register-title-text">Please set a new password using the form below.</div>
                    </div>
                  </div>
                  <div className="registration-form-container">
                    <Formik
                      initialValues={{
                        password: '',
                        password_confirmation: ''
                      }}
                      validate={values => {
                        const errors = {};
                        if (!values.password) {
                          errors.password = 'Required';
                        }
                        else if (values.password.length < 6) {
                          errors.password = 'Password is too short.  Minimum is 6 characters.'
                        }
                        else if (values.password !== values.password_confirmation) {
                          errors.password_confirmation = 'Passwords don\'t match.'
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values, setSubmitting)
                      }}
                    >
                      {({ errors, touched, isSubmitting }) => (
                        <Form>
                          <div className="account-text-field-container">
                            <Field type="password" name="password" placeholder="Please enter a password..." />
                              {errors.password && touched.password ? (
                                <div>{errors.password}</div>
                              ) : null}
                            <Field type="password" name="password_confirmation" placeholder="Please confirm your password..." />
                              {errors.password_confirmation && touched.password_confirmation ? (
                                <div>{errors.password_confirmation}</div>
                              ) : null}
                          </div>
                          <div className="save-changes-container">
                            <input type="submit" value="SUBMIT" />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            ) : (
              <div className="registration-container">
                <div className="registration-details">
                  <div className="register1-button">
                    <div className="registration-info-container">
                      <div className="register-title-text">Password updated successfully.<br /><br />
                      Please fill out the <Link to="/account">Account</Link> page to add Email Preferences, NHC Tour number, etc!</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  ))
}

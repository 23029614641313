import React from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import Obfuscate from 'react-obfuscate';

export default function Press() {
  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | Press</title>
        <meta name="description" content="Sport of Kings press releases." />
      </Helmet>
	<div className="content-wrapper">
	  <main>
	    <SOKSidebar />
	    <div className="main-homepage">
			<div className="contests-title-row">
			  <div className="homepage-header">Press Releases</div>
			</div>
			<div className="general-text-wrapper">
				<div className="general-text">
					<div className="about-logo-container">
						<img src="/img/sport-of-kings-complete-logo-white-on-black.svg" className="sok-complete-logo" />
					</div>
					<h2>Recent Press</h2>
					<p>Sept 22, 2021:<br /><a href="/docs/sok-keeneland-select-fall-handicapping-challenge.pdf" target="_blank">Sport of Kings Announces Keeneland Select Fall Handicapping Challenge</a>.
					</p>

					<h2>Inquiries</h2>
					<p>For more information contact us at: <Obfuscate email="publicity@sportofkings.net" />
					</p>

					</div>
				</div>
	    </div>
	  </main>
	</div>
    </div>
  )
}

import React from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import { Link, useParams } from "react-router-dom";
import { UserContext } from './App'
import Obfuscate from 'react-obfuscate';

export default function Podcast() {
  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | Podcast</title>
        <meta name="description" content="Sport of Kings Podcast" />
				<meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="The Sport of Kings Podcast" />
        <meta name="twitter:description" content="Listen here." />
        <meta name="twitter:image" content="https://storage.googleapis.com/sok-images/sok-podcast-graphic.png" />
        <meta property="og:image" content="https://storage.googleapis.com/sok-images/sok-podcast-graphic.png" />
      </Helmet>
	<div className="content-wrapper">
	  <main>
	    <SOKSidebar />
	    <div className="main-homepage">
				<div className="contests-title-row">
				  <div className="homepage-header">The Sport of Kings Podcast</div>
				</div>
						<div className="centered-image">
							<div className="podcast-image">
								<a href="https://podcasts.apple.com/us/podcast/sport-of-kings-podcast/id1456516619" target="_blank"><img src="/img/apple-podcasts.svg" height="44" alt="Apple Podcasts Logo" /></a>
							</div>
							<div className="podcast-image">
								<a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5zaW1wbGVjYXN0LmNvbS84aWZ6VHM5eg?sa=X&ved=0CAMQ4aUDahcKEwjws5rHwMfwAhUAAAAAHQAAAAAQDg" target="_blank"><img src="https://storage.googleapis.com/sok-images/google-podcasts.svg" height="44" alt="Google Podcasts Logo" /></a>
							</div>
							<div className="podcast-image">
								<a href="https://open.spotify.com/show/3EiKEQjHNSmb8AR9L60j1h" target="_blank"><img src="https://storage.googleapis.com/sok-images/spotify.svg" height="44" alt="Spotify Logo" /></a>
							</div>
							<div className="podcast-image">
								<a href="https://overcast.fm/itunes1456516619" target="_blank"><img src="https://storage.googleapis.com/sok-images/overcast-badge.svg" height="44" alt="Overcast Logo" /></a>
							</div>
						</div>				
				<div className="general-text-wrapper">
					<div className="general-text">
						<h2>Or listen here on Simplecast:</h2>
					<iframe height="500px" width="100%" scrolling="auto" seamless src="https://sport-of-kings-podcast.simplecast.com/episodes" style={{ background: '#fff', border: '1 solid white', margin: '.5em 0 1em' }}></iframe>

						<h2>Sport of Kings Podcast</h2>

						<p>Join <span style={{fontWeight: 'bold', color: '#fff'}}>Scott Carson</span> (6th place, NHC 2018) and <span style={{fontWeight: 'bold', color: '#fff' }}>Chris Larmey</span> (NHC Hall of Fame) for a weekly podcast with the sport’s finest horseplayer guests. Each week they share opinions and ideas on the best races of the weekend. 
						</p>						
						<p>The podcast usually drops on Friday afternoon for the next day’s racing. Typically we cover the late pick 5 from the track with the most appealing pick 5 sequence. 
						</p>
						<p>Wherever you get your podcasts, please <span style={{fontWeight: 'bold', color: '#fff' }}>SUBSCRIBE</span> to the Sport of Kings Podcast for earliest notification when new episodes are published!
						</p>
					<h2>Sponsorship Inquiries</h2>
					<p>To reach a quality handicapping audience, contact us at: <Obfuscate email="sponsorship@sportofkings.net" />
					</p>
					</div>
			</div>
			<div className="benefactors">
						<h2>Sport of Kings Podcast Guest List (alphabetical by first name)</h2>
	      <ul>
	      	<li style={{fontSize: '1em' , fontStyle: 'Italic', opacity: .7 }}>Updated through 8/3/21</li>
	      	<li>Aaron Vercruysse</li>
	      	<li>Andy Beyer</li>
	      	<li>Andy Serling</li>
	      	<li>Benny Southstreet</li>
	      	<li>Bill Shurman</li>
	      	<li>Brent Sumja</li>
	      	<li>Bruno DeJulio</li>
	      	<li>Charles Simon</li>
	      	<li>Chris Fallica</li>
	      	<li>Christopher Ado</li>
	      	<li>Craig Milkowski</li>
	      	<li>Dan Cronin</li>
	      	<li>Dan Illman</li>
	      	<li>Dan Tordjman</li>
	      	<li>David Aragona</li>
	      	<li>Dennis Decauwer</li>
	      	<li>Duke Matties</li>
	      	<li>Ed DeRosa</li>
	      	<li>Eddie Wright</li>
	      	<li>Ellis Starr</li>
	      	<li>Emily Gullickson</li>
	      	<li>Gino Buccola</li>
	      	<li>Henry Wessel</li>
	      	<li>Jake Jacobs</li>
	      	<li>Jason Beem</li>
	      	<li>Jason Perlmutter</li>
	      	<li>Jay Privman</li>
	      	<li>Jim Goodman</li>
	      	<li>Joe Koury</li>
	      	<li>John Doyle</li>
	      	<li>John Nichols</li>
	      	<li>Jon “Hurricane” Hurd</li>
	      	<li>Jonathon Kinchen</li>
	      	<li>Judy Wagner</li>
	      	<li>Ken Jordan</li>
	      	<li>Marcus Hersh</li>
	      	<li>Marshall Gramm</li>
	      	<li>Matt Bernier</li>
	      	<li>Maury Wolff</li>
	      	<li>Michael Beychok</li>
	      	<li>Mike Maloney</li>
	      	<li>Mike Somich</li>
	      	<li>Mitch Schuman</li>
	      	<li>Nick Tammaro</li>
	      	<li>Pat Gianforte</li>
	      	<li>Paul Matties Jr</li>
	      	<li>Paul Shurman</li>
	      	<li>Paul Weizer</li>
	      	<li>Pete Fornatale</li>
	      	<li>Ray Arsenault</li>
	      	<li>Rich Nilsen</li>
	      	<li>Ross Gallo</li>
	      	<li>Scott Coles</li>
	      	<li>Steve Byk</li>
	      	<li>Steve Thompson</li>
	      	<li>Steve Wolfson Sr</li>
	      	<li>Steve Wolfson Jr</li>
	      	<li>Tommy “Hammer” Massis</li>
	      	<li>Tony Zhou</li>
	      	<li>Travis Stone</li>
	        </ul>
        </div>
	    </div>
	  </main>
	</div>
    </div>
  )
}

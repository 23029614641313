import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import ContestEntryCard from './ContestEntryCard'
import axios from 'axios';

export default function Odds() {
  const [contests, setContests] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}contests?status=active_not_upcoming&include=contest_races`)
      .then(res => {
        setContests(res.data)
      })
  }, [])

  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | Odds</title>
        <meta name="description" content="Sport of Kings odds of horses in contest races based on player selections." />
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="main-make-picks">
            <div className="make-picks-column">
              <div className="contests-title-row">
                <div className="make-picks-header">Odds</div>
              </div>
              { contests.filter(c => c.contest_type !== 'Series').sort((a,b) => {
                if (a.next_upcoming_race_exists && !b.next_upcoming_race_exists) return -1;
                if (!a.next_upcoming_race_exists && b.next_upcoming_race_exists) return 1;
                else return new Date(a.next_upcoming_race_starts_at) - new Date(b.next_upcoming_race_starts_at)
              }).map((contest, contestIndex) =>
                <ContestEntryCard contest={contest} contestRaces={contest.contest_races} contestIndex={contestIndex} destination='odds' key={contest.id} />
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

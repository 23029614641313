import React, { useState, useEffect, useContext } from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import ContestEntryCard from './ContestEntryCard'
import { UserContext } from './App'
import axios from 'axios';

export default function PastPerformances() {
  const [contests, setContests] = useState([])
  const { userData } = useContext(UserContext)

  useEffect(() => {
    if (userData.token) {
      axios.get(`${process.env.REACT_APP_API_URL}contests?status=active_not_upcoming&include=past_performances`)
        .then(res => {
          setContests(res.data)
        })
    }
  }, [userData])

  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | Free Past Performances</title>
        <meta name="description" content="Past performances for horse races on Sport of Kings are always free." />
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="past-performances-wrapper">
            <div className="main-make-picks">
              <div className="make-picks-column">
                <div className="contests-title-row">
                  <div className="make-picks-header">Past Performances</div>
                </div>
                {!userData.token ? (
                  <h2 style={{color: '#fff'}}>You must sign in or register to view Past Performances.</h2>
                ) : (
                  contests.filter(c => c.contest_type !== 'Series').sort((a,b) => {
                    if (a.next_upcoming_race_exists && !b.next_upcoming_race_exists) return -1;
                    if (!a.next_upcoming_race_exists && b.next_upcoming_race_exists) return 1;
                    else return new Date(a.next_upcoming_race_starts_at) - new Date(b.next_upcoming_race_starts_at)
                  }).map((contest, contestIndex) =>
                    <ContestEntryCard contest={contest} contestRaces={contest.contest_races} contestIndex={contestIndex} destination='standings' key={contest.id} mode='pps' />
                  )
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import { Formik, Form, Field, useFormikContext } from 'formik'
import $ from 'jquery'

export default function UserEmailPreferences({ userData, handleAccountUpdate, heading = 'Email Preferences' }) {
  useEffect(() => {
    $('.account-button').off('click');
    $('.account-button').on('click', function(e) {
      if ($(this).find('.changeCarat').attr('src') === 'img/white-carat-down.svg') {
        $(this).find('.changeCarat').attr('src', 'img/white-carat-up.svg');
      }
      else {
        $(this).find('.changeCarat').attr('src', 'img/white-carat-down.svg');
      }
      $(this).next('.account-reveal').slideToggle();
    });
  }, [userData])

  return (
    <div className="account-details">             
      <div className="account-wrapper">
        <div className="account-button">
          <div className="account-button-container">
            <div className="account-title-text">{ heading }</div>
            <div className="carat">
              <img src="img/white-carat-down.svg" alt="" className="changeCarat" />
            </div>
          </div>
        </div>
        <div className="account-reveal" style={{ display: 'none' }}>
          <Formik
            initialValues={{ 
              get_weekly_email: userData.player.get_weekly_email,
              get_new_contest_email: userData.player.get_new_contest_email,
              get_picks_alert_email: userData.player.get_picks_alert_email
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleAccountUpdate(values, setSubmitting)
              alert('Changes saved.')
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <div className="email-prefs-container">
                  <div className="email-prefs-checkbox">
                    <Field type="checkbox" name="get_weekly_email" id="get_weekly_email" className="larger-checkbox" />
                    <label htmlFor="get_weekly_email"></label>
                  </div>
                  <div className="account-page-white-text">Weekly email with announcement of contests and races (usually goes out Thursday evening). Weekly closing email round-up of results for all contests (usually goes out Monday morning).</div>
                </div>
                <div className="email-prefs-container" style={{ display: values.get_weekly_email ? 'flex' : 'none' }}>
                  <div className="email-prefs-checkbox">
                    <Field type="checkbox" name="get_new_contest_email" id="get_new_contest_email" className="larger-checkbox" />
                    <label htmlFor="get_new_contest_email"></label>
                  </div>
                  <div className="account-page-white-text">Notification of any unique contest start that is now taking entries.</div>
                </div>
                <div className="email-prefs-container" style={{ display: values.get_weekly_email ? 'flex' : 'none' }}>
                  <div className="email-prefs-checkbox">
                    <Field type="checkbox" name="get_picks_alert_email" id="get_picks_alert_email" className="larger-checkbox" />
                    <label htmlFor="get_picks_alert_email"></label>
                  </div>
                  <div className="account-page-white-text">Reminder to make picks for any contest day that you have entered, but not made picks for, within 2 hours of first post.</div>
                </div>
                <div className="save-changes-container">
                  <input type="submit" value="SAVE CHANGES" disabled={isSubmitting} />
                </div>
                <UserEmailPreferencesHandler />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

const UserEmailPreferencesHandler = () => {
  const {
    values: { get_weekly_email, get_new_contest_email, get_picks_alert_email },
    touched,
    setFieldValue,
  } = useFormikContext()

  useEffect(() => {
    if (!get_weekly_email) {
      setFieldValue('get_new_contest_email', false)
      setFieldValue('get_picks_alert_email', false)
    }
  }, [get_weekly_email, get_new_contest_email, get_picks_alert_email, touched])

  return null
}
import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import UserEmailPreferences from './UserEmailPreferences'
import axios from 'axios'
import $ from 'jquery'

export default function Unsubscribe() {
  const [playerHash, setPlayerHash] = useState('')
  const [unsubscribed, setUnsubscribed] = useState(false)
  const [tempUserData, setTempUserData] = useState({ loaded: false, token: false, handle: '' })

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    let h = query.get('h')
    let u = query.get('u')
    if (u) {
      axios.post(`${process.env.REACT_APP_API_URL}unsubscribe`, {
        h: h,
        preferences: {
          get_new_contest_email: false,
          get_picks_alert_email: false,
          get_weekly_email: false
        }
      }).then(res => {
        setPlayerHash(h)
        setUnsubscribed(true)
        setTempUserData({ loaded: true })
      }).catch(error => {
        alert('Email address not found.')
      })
    }
    else {
      axios.get(`${process.env.REACT_APP_API_URL}player_by_hash?h=${h}`).then(res => {
        setTempUserData(res.data)
        $('.account-reveal').show();
        $('.changeCarat').attr('src', 'img/white-carat-up.svg');
      })
    }
  }, [])

  function handleAccountUpdate(values, setSubmitting) {
    axios.post(`${process.env.REACT_APP_API_URL}unsubscribe`, {
      h: tempUserData.player.unique_hash,
      preferences: values
    }).then(res => {
      setSubmitting(false)
    }).catch(error => {
      setSubmitting(false)
    })
  }

  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Unsubscribe | Sport of Kings</title>
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          {tempUserData.loaded && (
            <div className="contests-column">
              <div className="contests-title-row">
                <div className="contests-header">Unsubscribe/Manage Email Preferences</div>
              </div>
              <div className="account-container">
                { tempUserData.player &&
                <UserEmailPreferences userData={tempUserData} handleAccountUpdate={handleAccountUpdate} heading={ `Email Preferences for: ${tempUserData.player.email}` } />
                }
                { unsubscribed &&
                  <div className="account-details">             
                    <div className="account-wrapper">
                      <div className="account-button">
                        <div className="account-button-container">
                          <div className="account-title-text">You have been unsubscribed.</div>
                        </div>
                      </div>
                      <div className="account-reveal">
                        <div className="save-changes-container">
                          <a href={`/unsubscribe?h=${playerHash}`}>Click here if you want to resubscribe.</a>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  )
}

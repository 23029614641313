import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Switch, Route, useLocation } from "react-router-dom";
import About from './About';
import Benefactors from './Benefactors';
import Contests from './Contests';
import Contest from './Contest';
import ContestOdds from './ContestOdds';
import ContestPicks from './ContestPicks';
import Event from './Event';
import Invite from './Invite';
import Leaderboards from './Leaderboards';
import LeaderboardsStage from './LeaderboardsStage';
import MakePicks from './MakePicks';
import Odds from './Odds';
import PastPerformances from './PastPerformances';
import Podcast from './Podcast';
import Press from './Press';
import Standings from './Standings';
import Standingsads from './Standingsads';
import SuccessStories from './SuccessStories';
import TShirtOrder from './TShirtOrder';
import Unsubscribe from './Unsubscribe';
import UserAccount from './UserAccount';
import UserLogin from './UserLogin';
import UserPassword from './UserPassword';
import UserPasswordConfirm from './UserPasswordConfirm';
import UserRegister from './UserRegister';
import UserRegisterWizard from './UserRegisterWizard';
import SOKNav from './SOKNav'
import SOKFooter from './SOKFooter';
import axios from 'axios';
import ReactGA from 'react-ga';
import './css/normalize.css'
import './css/main.css'

export const UserContext = React.createContext();
const LOCAL_STORAGE_KEY = 'sportofkings.userData'

axios.defaults.headers.post['Content-Type'] = 'application/json';

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(trackingId)

function App() {
  const [userData, setUserData] = useState({ loaded: false, token: false, handle: '' })
  const userContextValue = {
    userData,
    setUserData
  }
  let location = useLocation()

  useEffect(() => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  }, [location])

  // Stash user data in Local Storage to persist logins between requests.
  useEffect(() => {
    // @todo handle timeout with JWT when refreshing session.
    const userJSON = localStorage.getItem(LOCAL_STORAGE_KEY)
    if (userJSON != null) {
      let ud = JSON.parse(userJSON)
      if (ud.token) {
        // Check that token is still valid.
        axios.defaults.headers.common['Authorization'] = "Token " + ud.token
        axios.get(`${process.env.REACT_APP_API_URL}player`)
          .then(res => {
            setUserData({
              loaded: true,
              player: res.data.player,
              token: res.data.player.token
            })
          }).catch(error => {
            // alert('Your session has expired.')
            setUserData({ loaded: true, token: false, player: {} })
          })
      }
      else {
        setUserData({ loaded: true, token: false, player: {} })
      }
    }
    else {
      setUserData({ loaded: true, token: false, player: {} })
    }
  }, [])

  useEffect(() => {
    if (userData.token) {
      axios.defaults.headers.common['Authorization'] = "Token " + userData.token
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userData))
  }, [userData])

  useEffect(() => {
    const landingPage = localStorage.getItem('sportofkings.landingPage')
    if (!landingPage) {
      localStorage.setItem('sportofkings.landingPage', document.referrer)
    }
  })

  return (
    <UserContext.Provider value={userContextValue}>
      <Helmet>
        <title>Sport of Kings | A Celebration of Horse Racing</title>
        <meta name="description" content="Sport of Kings is a free horse racing contest website with free past performances." />
      </Helmet>
      <SOKNav />
      <Switch>
        <Route path="/past-performances">
          <PastPerformances />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/benefactors">
          <Benefactors />
        </Route>
        <Route path="/password/confirm">
          <UserPasswordConfirm />
        </Route>
        <Route path="/password">
          <UserPassword />
        </Route>
        <Route path="/register/wizard">
          <UserRegisterWizard />
        </Route>
        <Route path="/register">
          <UserRegister />
        </Route>
        <Route path="/account">
          <UserAccount />
        </Route>
        <Route path="/unsubscribe">
          <Unsubscribe />
        </Route>
        <Route path="/make-picks">
          <MakePicks />
        </Route>
        <Route path="/odds">
          <Odds />
        </Route>
        <Route path="/podcast">
          <Podcast />
        </Route>
        <Route path="/press">
          <Press />
        </Route>
        <Route path="/standings">
          <Standings />
        </Route>
        <Route path="/standingsads">
          <Standingsads />
        </Route>
        <Route path="/success-stories">
          <SuccessStories />
        </Route>
        <Route path="/tshirt-order">
          <TShirtOrder />
        </Route>
        <Route path={`/events/:eventId`}>
          <Event />
        </Route>
        <Route path={`/contests/:contestId/picks`}>
          <ContestPicks />
        </Route>
        <Route path={`/contests/:contestId/odds`}>
          <ContestOdds />
        </Route>
        <Route path={`/contests/:contestId/standings`}>
          <Standings />
        </Route>
        <Route path={`/contests/:contestId`}>
          <Contest />
        </Route>
        <Route path="/contests">
          <Contests />
        </Route>
        <Route path={`/invite/:inviteCode`}>
          <Invite />
        </Route>
        <Route path="/leaderboardsstage">
          <LeaderboardsStage />
        </Route>
        <Route path="/">
          <Leaderboards />
        </Route>
      </Switch>
      <SOKFooter />
      <UserLogin />
    </UserContext.Provider>
  )
}

export default App;

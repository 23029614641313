import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import PicksButton from './PicksButton'
import ReactMarkdown from 'react-markdown';
import $ from 'jquery'

export default function ContestCard({ contest, handleEnterClick }) {
  useEffect(() => {
    $('.contest-button').off('click')
    $('.contest-button').on('click', function(e) {
      $(this).parent().next('div').slideToggle()
    })
    $('.contest-rules').off('click')
    $('.contest-rules').on('click', function(e) {
      $(this).next('.contest1-rules-reveal').slideToggle()
    })
  }, [])

  let standingsText = 'Standings'
  if (contest.upcoming && contest.qualification_required) standingsText = 'Qualifiers'
  if (contest.upcoming && contest.invitation_only) standingsText = 'Contestants'

  return (
    <ol className="contest-details">
      <li className="contest-title">
        <span className="contest-button"><div className="contest-button-container">{contest.name}</div></span>
      </li>
      <div className="contest-reveal">
        <li className="contest-detail-button-container">
          <div className="outlined-button"><Link to={'/contests/' + contest.id + '/standings'} className="detail-outlined-buttons">{standingsText}</Link></div>
          { (contest.entered || (!contest.qualification_required && contest.contest_type !== 'Series')) &&
            <div className="outlined-button">
              <PicksButton contest={contest} handleEnterClick={handleEnterClick} className='detail-outlined-buttons' />
            </div>
          }
        </li>
        <li className="contest-format">
          <div className="format-word">Format</div>
          <div className="format-text"><ReactMarkdown>{contest.format}</ReactMarkdown></div>
        </li>
        <li className="contest-prizes">
          <div className="contest-prizes-word">Prizes</div>
          <div className="contest-prizes-text"><ReactMarkdown>{contest.prizes}</ReactMarkdown></div>
        </li>
        <li className="contest-entry">
          <div className="contest-entry-word">Entry</div>
          <div className="contest-entry-text"><ReactMarkdown>{contest.entry}</ReactMarkdown></div>
        </li>
        <li className="contest-schedule">
          <div className="contest-schedule-word">Schedule</div>
          <div className="contest-schedule-text">{ contest.schedule }</div>
        </li>
        <li className="contest-rules">
          <div className="contest-rules-word"><span>Complete Rules</span></div>       
        </li>
        <div className="contest1-rules-reveal">
          <div className="complete-rules">
            <ReactMarkdown>{contest.rules}</ReactMarkdown>
          </div>
        </div>
      </div>
    </ol>
  )
}

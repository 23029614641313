import React, { useState, useEffect, useContext } from 'react';
import { useParams, Redirect, Link } from "react-router-dom";
import SOKSidebar from './SOKSidebar'
import ReactMarkdown from 'react-markdown';
import { UserContext } from './App'
import { Formik, Form, Field } from 'formik';
import axios from 'axios';

export default function Invite() {
  let { inviteCode } = useParams();
  const { userData, setUserData } = useContext(UserContext)
  const [inviteProcessed, setInviteProcessed] = useState(false)
  const [inviteEnrolled, setInviteEnrolled] = useState(false)
  const [contestId, setContestId] = useState(false)
  const [contestName, setContestName] = useState('')

  useEffect(() => {
    // Wait for userData to load
    if (userData.loaded) {
      // Validate invite code.
      axios.get(`${process.env.REACT_APP_API_URL}invite/${inviteCode}`)
        .then(res => {
          // If the player was enrolled, they're already signed in.
          console.log(res.data)
          if (res.data.enrolled) {
            setInviteEnrolled(true)
            setContestId(res.data.contest_id)
            alert('You have been entered in the ' + res.data.contest_name + ' contest.')
          }
          setContestName(res.data.contest_name)
          setInviteProcessed(true)
        }).catch(error => {
          if (error.response.status == 404) {
            alert('Invalid invite code.  Please check the code and try again.')
          }
          else {
            alert('Sorry, an unknown error happened.')
          }
        })
    }
  }, [inviteCode, userData])

  function handleRegisterSubmit(values, setSubmitting) {
    axios.post(`${process.env.REACT_APP_API_URL}players`, {
      player: values
    }).then(res => {
      axios.defaults.headers.common['Authorization'] = "Token " + res.data.player.token
      setUserData({
        loaded: true,
        player: res.data.player,
        token: res.data.player.token
      })
      setSubmitting(false)
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.email && error.response.data.errors.email[0] === 'has already been taken') {
        alert('An account with your email address already exists.')
      }
      else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.handle && error.response.data.errors.handle[0] === 'has already been taken') {
        alert('An account with your player name already exists.')
      }
      else {
        alert('An unknown error occurred while registering your account.')
      }
      setSubmitting(false)
    })
  }

  // We don't know yet whether players are signed in or not, so just wait.
  if (!inviteProcessed) {
    return (
      <div className="outer-wrapper">
        <div className="content-wrapper">
          <main>
            <SOKSidebar />
            <div className="main-homepage">
              <div className="contests-title-row">
                <div className="contests-header">INVITE PROCESSING, PLEASE WAIT...</div>
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }

  // Player is signed in and already enrolled, just redirect 'em to the contest.
  if (userData.loaded && inviteEnrolled && contestId) {
    return <Redirect to={ '/contests/' + contestId } />
  }

  // Player is not signed in.  Offer simple registration or sign in.
  return (
    <div className="outer-wrapper">
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="registration-account-column">
            <div className="contests-title-row">
              <div className="contests-header">REGISTER FOR THE {contestName} CONTEST</div>
            </div>
            <div className="registration-container">
              <div className="registration-details">
                <div className="register1-button">
                  <div className="registration-info-container">
                    <div className="register-title-text">Welcome to Sport of Kings!</div>
                  </div>
                </div>
                <div className="registration-form-container">
                  <Formik
                    initialValues={{
                      email: '',
                      first_name: '',
                      last_name: '',
                      password: '',
                      password_confirmation: ''
                    }}
                    validate={values => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = 'Required';
                      }
                      if (!values.first_name) {
                        errors.first_name = 'Required';
                      }
                      if (!values.last_name) {
                        errors.last_name = 'Required';
                      }
                      if (!values.password) {
                        errors.password = 'Required';
                      }
                      else if (values.password.length < 6) {
                        errors.password = 'Password is too short.  Minimum is 6 characters.'
                      }
                      else if (values.password !== values.password_confirmation) {
                        errors.password_confirmation = 'Passwords don\'t match.'
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      handleRegisterSubmit(values, setSubmitting)
                    }}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form>
                        <div className="account-text-field-container">
                          <div className="registration-text">
                            <p>Please register to enroll in the {contestName} contest.</p>
                            <p><a onClick={() => document.getElementById('signin-checkbox').checked = true }>If you already have an account with us, please click here to sign in.</a></p>
                            <br />
                            <p>Please enter your email address.</p>
                          </div>
                          <div className="registration-text-field-container">
                            <Field type="email" name="email" placeholder="Enter email address..." />
                            {errors.email && touched.email ? (
                              <div>{errors.email}</div>
                            ) : null}
                          </div>
                          <div className="registration-text">
                            <p>Enter your first name.</p>
                          </div>
                          <div className="registration-text-field-container">
                            <Field type="text" name="first_name" maxlength="18" placeholder="Enter first name..." />
                            {errors.first_name && touched.first_name ? (
                              <div>{errors.first_name}</div>
                            ) : null}
                          </div>
                          <div className="registration-text">
                            <p>Enter your last name.</p>
                          </div>
                          <div className="registration-text-field-container">
                            <Field type="text" name="last_name" maxlength="18" placeholder="Enter last name..." />
                            {errors.last_name && touched.last_name ? (
                              <div>{errors.last_name}</div>
                            ) : null}
                          </div>
                          <div className="registration-text">
                            <p>Please choose a password.</p>
                          </div>
                          <div className="registration-text-field-container">
                            <Field type="password" name="password" placeholder="Please enter a password..." />
                              {errors.password && touched.password ? (
                                <div>{errors.password}</div>
                              ) : null}
                            <Field type="password" name="password_confirmation" placeholder="Please confirm your password..." />
                              {errors.password_confirmation && touched.password_confirmation ? (
                                <div>{errors.password_confirmation}</div>
                              ) : null}
                          </div>
                        </div>
                        <div className="save-changes-container">
                          <input type="submit" value="REGISTER" />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

import React, { useState, useEffect, useContext } from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import { Redirect,useParams } from "react-router-dom";
import { UserContext } from './App'
import axios from 'axios';
import Moment from 'moment';
import moment from 'moment-timezone';
import Countdown from 'react-countdown';
import { ActionCableConsumer } from 'react-actioncable-provider';
import UserPicks from './UserPicks'
import Carousel from 'react-elastic-carousel'
import $ from 'jquery'

export default function ContestPicks() {
  const [contests, setContests] = useState([])
  const { userData } = useContext(UserContext)
  const [contest, setContest] = useState({})
  const [race, setRace] = useState({})
  const [picks, setPicks] = useState([])
  const [bettingOpen, setBettingOpen] = useState(false)
  let { contestId } = useParams();

  useEffect(() => {
    if (userData.token) {
      // Get active contests.
      axios.get(`${process.env.REACT_APP_API_URL}contests?status=active_not_upcoming`)
        .then(res => {
          setContests(res.data.filter(c => c.entered))
        })

      // Contest ID in the URL is the default.
      handleContestClick(contestId)
    }
  }, [contestId, userData])

  function handleContestClick(selectedContestId) {
    const query = new URLSearchParams(window.location.search)
    let selectedRace = query.get('race')

    axios.get(`${process.env.REACT_APP_API_URL}contests/${selectedContestId}`)
      .then(res => {
        setContest(res.data)
        if (res.data.contest_type == 'Pick N Pray') {
          setBettingOpen(res.data.betting_open)
        }
        axios.get(`${process.env.REACT_APP_API_URL}picks/${selectedContestId}`)
          .then(pres => {
            setPicks(pres.data)
            let handledRaceClick = false
            if (selectedRace) {
              let selectedContestRace = res.data.contest_races.find(cr => cr.race.id === parseInt(selectedRace))
              if (selectedContestRace) {
                handleRaceClick(selectedRace, selectedContestRace.delta, res.data)
                handledRaceClick = true
              }
            }

            if (!handledRaceClick) {
              const openRaces = res.data.contest_races.filter(contestRace => contestRace.betting_open)
              if (openRaces.length > 0) {
                handleRaceClick(openRaces[0].race.id, openRaces[0].delta, res.data)
              }
              else {
                const lastRace = res.data.contest_races.slice(-1)[0]
                handleRaceClick(lastRace.race.id, lastRace.delta, res.data)
              }
            }

            $('#other-standings').slideUp()
            $('#other-standings-carat').attr('src', '/img/white-carat-down.svg');

          }).catch(error => {
          })
      })
  }

  function handleRaceClick(raceId, delta, c = null) {
    if (!c) {
      c = contest
    }
    axios.get(`${process.env.REACT_APP_API_URL}races/${raceId}`)
      .then(res => {
        res.data.delta = delta
        setRace(res.data)
        if (c.contest_type != 'Pick N Pray') {
          setBettingOpen(res.data.betting_open)
        }
      })
  }

  function handlePickClick(entryId, delta) {
    axios.post(`${process.env.REACT_APP_API_URL}picks/${contest.id}`, {
      entry_id: entryId,
      delta: delta
    }).then(res => {
      setPicks(res.data)
    })
  }

  function handleRacePager(offset) {
    const currentContestRaceDelta = contest.contest_races.findIndex(cr => cr.race.id === race.id)
    const nextContestRaceDelta = currentContestRaceDelta + offset
    if (contest.contest_races[nextContestRaceDelta]) {
      handleRaceClick(contest.contest_races[nextContestRaceDelta].race.id, contest.contest_races[nextContestRaceDelta].delta);
    }
  }

  const checkCountdownMessage = ({ hours, minutes, seconds, completed }) => {

  }

  const closeBetting = () => {
    setRace(prevRace => ({...prevRace, betting_open: false}))
    setBettingOpen(false)
    alert('Picks are closed for this race.')
  }

  const breakPoints = [
  { width: 1, itemsToShow: 3, itemsToScroll: 1},
  { width: 550, itemsToShow: 3, itemsToScroll: 1 },
  { width: 768, itemsToShow: 3, itemsToScroll: 1 },
  { width: 1200, itemsToShow: 3, itemsToScroll: 1 }
  ]

  function handleReceivedRace(response) {
    console.log('Picks update received!')
    response.race.delta = race.delta
    setRace(response.race)
  }

  function toggleMoreContests() {
    if (contests.length > 1) {
      if ($('#other-standings-carat').attr('src') === '/img/white-carat-down.svg') {
        $('#other-standings-carat').attr('src', '/img/white-carat-up.svg');
      }
      else {
        $('#other-standings-carat').attr('src', '/img/white-carat-down.svg');
      }
      $('#other-standings').slideToggle()
    }
  }


  Moment.locale('en')

  return ((userData.loaded && !userData.token) || (contest.name && !contest.entered)) ? (
    <Redirect to="/" />
  ) : (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | Make Picks</title>
      </Helmet>
      <div className="content-wrapper">
        <div className="make-picks-main-wrapper">
          <main>
            <SOKSidebar />
            <div className="main-make-picks">
              <div className="make-picks-column">
                <div className="contests-title-row">
                  <div className="make-picks-header">Make Your Picks</div>
                </div>
                <div className="make-picks-container">
                  <div className="contest-details">
                    <div className="contest-title">
                      <div className="contest1-button">
                        <div className="contest-button-container" onClick={toggleMoreContests}>
                          { contests.length ? (
                            <>
                              <div className="contest-title-text">
                                {contest.name}
                              </div>
                              <div className="carat">
                                <img src="/img/white-carat-down.svg" className="changeCarat" id="other-standings-carat" alt="Select Different Contests Button" />
                              </div>
                              <div className="more-standings" id="other-standings">
                                { contests.filter((c) => c.id != contest.id && !c.finished && !c.upcoming).map((c) => 
                                  <div key={c.id} className="contest-title-text" onClick={() => handleContestClick(c.id)}>{c.name}</div>
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="contest-title-text">{contest.name}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="contest1-reveal">
                      { race.id && (
                        <div className="make-picks-race-detail-row">
                          <ActionCableConsumer
                            channel={{ channel: 'PicksChannel', race_id: race.id }}
                            onReceived={handleReceivedRace}
                          />
                          <div className="event-number">
                            <p>Event {race.delta}</p>
                          </div>
                          <div className="event-details">
                            <p>{race.short_name} <span className="gray-text">({Moment(race.race_starts_at).format('M/D')})</span></p>
                          </div>
                          <div className="event-time">
                            <p><span className="gray-text">Post time:</span> {Moment(race.race_starts_at).tz(moment.tz.guess()).format('h:mma z')}</p>
                          </div>
                          <div className="picks-deadline">Picks Deadline:&nbsp;
                            <span className="timer-color">
                            { bettingOpen ? 
                              <Countdown
                                key={contest.contest_type == 'Pick N Pray' ? contest.betting_closes_at : race.betting_closes_at}
                                date={contest.contest_type == 'Pick N Pray' ? contest.betting_closes_at : race.betting_closes_at}
                                daysInHours={true}
                                onTick={checkCountdownMessage}
                                onComplete={closeBetting}
                              />
                            :
                              <span>Closed</span>
                            }
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="make-picks-event-row">
                        { contest.contest_races && 
                          <Carousel className="upcoming-races" breakPoints={breakPoints}>
                            {contest.contest_races.map((contestRace, index) =>
                              <div key={contestRace.id} className={(race && race.id === contestRace.race.id) ? 'event-card-active' : 'event-card'} onClick={() => handleRaceClick(contestRace.race.id, contestRace.delta)}>
                                <div className="event-date">{ Moment(contestRace.race.race_starts_at).format('M/D') }</div>
                                <div className="event-track-and-race">{contestRace.race.short_name}</div>
                              </div>
                            )}
                          </Carousel>
                        }
                      </div>
                      { race.id && (
                        <div className="make-picks-race-container">
                          <div className="make-picks-heading-row">
                            <div className="program-number"><p>#</p></div>
                            <div className="post-position"><p>PP</p></div>
                            <div className="horse-name"><p>Horse</p></div>
                            <div className="ml-odds"><p>ML Odds</p></div>
                            <div className="live-odds"><p>Live<br />Odds</p></div>
                            <div className="jockey-name"><p>Jockey</p></div>
                            <div className="trainer-name"><p>Trainer</p></div>
                            <div className="main-pick"><p>Main Pick</p></div>
                            <div className="alt-pick"><p>Alt. Pick</p></div>
                          </div>
                          { !bettingOpen &&
                            <h2>Picks are closed for this race.</h2>
                          }
                          <ol>
                            { race.entries.map((entry) =>
                              <MakePicksEntrantRow entry={entry} race={race} picks={picks} handlePickClick={handlePickClick} key={entry.id} bettingOpen={bettingOpen} />
                            )}
                          </ol>
                        </div>
                      )}
                      <div className="make-picks-next-race-row">
                        {race.delta > 1 ?
                          <div className="previous-race" onClick={() => handleRacePager(-1)}>
                            <p><img src="/img/blue-carat-left.svg" alt="Previous Race Button" />Prev Race</p>
                          </div>
                          :
                          <div className="previous-race"></div>
                        }
                        {contest.contest_races && race.delta < contest.contest_races.length &&
                          <div className="next-race" onClick={() => handleRacePager(1)}>
                            <p>Next Race<img src="/img/blue-carat-right.svg" alt="Next Race Button" /></p>
                          </div>
                        }
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <UserPicks contest={contest} picks={picks} handleRaceClick={handleRaceClick} />
          </main>
       </div>
      </div>
    </div>
  )
}

const MakePicksEntrantRow = ({entry, race, picks, handlePickClick, bettingOpen}) => {
  let rowClasses = 'make-picks-race-entrant-row'
  if (entry.morning_line_odds_human === 'SCR') {
    rowClasses += ' scratched'
  }
  return (
    <li className={rowClasses} key={entry.id}>
      <div className="program-number">
        <div className={`saddle-cloth-${entry.program_number && entry.program_number.replace(/\D/g,'')}`}><p>{entry.program_number}</p></div>
      </div>
      <div className="post-position"><p>{entry.post_position}</p></div>
      <div className="horse-name"><p>{entry.horse.name}</p></div>
      <div className="ml-odds"><p>{entry.morning_line_odds_human}</p></div>
      <div className="live-odds"><p>12-1</p></div>
      <div className="jockey-name"><p>{entry.jockey.name}
        <br />
        { entry.weight &&
          <span className="weight">{ entry.weight }Lbs</span>
        }
      </p></div>
      <div className="trainer-name"><p>{entry.trainer.name}</p></div>
      <div className="main-pick">
        { entry.morning_line_odds_human !== 'SCR' &&
          <label className="radio-button-container">
            <input
              type="radio"
              name="mainPick"
              onChange={() => handlePickClick(entry.id, 1)}
              checked={picks.some((pick) => pick.entry_id === entry.id && pick.delta === 1)}
              disabled={!bettingOpen || entry.morning_line_odds_human === 'SCR' || picks.some((pick) => pick.entry_id === entry.id && pick.delta === 2)}
            />
            <span className="radio-checkmark"></span>
          </label>
        }
      </div>
      <div className="alt-pick">
        { entry.morning_line_odds_human !== 'SCR' &&
          <label className="radio-button-container">
            <input
              type="radio"
              name="altPick"
              onChange={() => handlePickClick(entry.id, 2)}
              checked={picks.some((pick) => pick.entry_id === entry.id && pick.delta === 2)}
              disabled={!bettingOpen || entry.morning_line_odds_human === 'SCR' || picks.some((pick) => pick.entry_id === entry.id && pick.delta === 1)}
            />
            <span className="radio-checkmark"></span>
          </label>
        }
      </div>
    </li>
  )
}

import React, { useState, useContext, useEffect } from 'react'
import { Redirect, Link } from "react-router-dom";
import { UserContext } from './App'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import StateProvinceOptions from './StateProvinceOptions'
import CountryOptions from './CountryOptions'
import UserPersonalInfo from './UserPersonalInfo'
import SOKSidebar from './SOKSidebar'
import axios from 'axios';
import 'animate.css';

export default function UserRegisterWizard() {
  const [step, setStep] = useState(false)
  const [password, setPassword] = useState('')
  const { userData, setUserData } = useContext(UserContext)

  useEffect(() => {
    console.log(userData.player)
    const query = new URLSearchParams(window.location.search)
    let token = query.get('token')
    if (token !== null && !userData.loaded) {
      axios.get(`${process.env.REACT_APP_API_URL}players/confirmation?confirmation_token=${token}`)
        .then(res => {
          setUserData({
            loaded: true,
            player: res.data.player,
            token: res.data.player.token
          })
          setStep('PasswordOne')
        })
        .catch(error => {
          alert('Invalid token.')
          setStep('InvalidToken')
        })
    }
    else if (token === null && userData.loaded && userData.token && !step) {
      setStep('PlayerName')
    }
    else if (token === null && userData.loaded && !userData.token) {
      document.getElementById("signin-checkbox").checked = true
    }
  }, [userData, setUserData])

  function handleStepSubmit(values, setSubmitting) {
    if (values.back) {
      setStep(values.back)
    }
    else {
      if (step === 'PasswordOne') {
        setSubmitting(false)
        setPassword(values.password)
        setStep('PasswordTwo')
      }
      else if (step === 'PasswordTwo') {
        values.password_set = true
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerName')
      }
      else if (step === 'PlayerName') {
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerRealName')
      }
      else if (step === 'PlayerRealName') {
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerStateProvince')
      }
      else if (step === 'PlayerStateProvince') {
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerAddress')
      }
      else if (step === 'PlayerAddress') {
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerCity')
      }
      else if (step === 'PlayerCity') {
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerZipCode')
      }
      else if (step === 'PlayerZipCode') {
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerPhone')
      }
      else if (step === 'PlayerPhone') {
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerTwitter')
      }
      else if (step === 'PlayerTwitter') {
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerNHC')
      }
      else if (step === 'PlayerNHC') {
        handleAccountUpdate(values, setSubmitting)
        setStep('HowDidYouHear')
      }
      else if (step === 'HowDidYouHear') {
        handleAccountUpdate(values, setSubmitting)
        setStep('YearStarted')
      }
      else if (step === 'YearStarted') {
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerGender')
      }
      else if (step === 'PlayerGender') {
        handleAccountUpdate(values, setSubmitting)
        setStep('PlayerAge')
      }
      else if (step === 'PlayerAge') {
        handleAccountUpdate(values, setSubmitting)
        setStep('Confirm')
      }
    }
  }

  function handleAccountUpdate(values, setSubmitting) {
    axios.patch(`${process.env.REACT_APP_API_URL}player`, {
      player: values
    }).then(res => {
      setUserData({
        loaded: true,
        player: res.data.player,
        token: res.data.player.token
      })
      setSubmitting(false)
    }).catch(error => {
      setSubmitting(false)
      alert('Update failed.') // @todo show specific error message, if possible
    })
  }

  return (step === 'InvalidToken' || step === 'Complete') ? (
    <Redirect to="/" />
    ) : (
    <div className="outer-wrapper">
      <div className="content-wrapper">
        <div className="main-registration-wrapper">
          <main>
            <SOKSidebar />
            <div className="registration-account-column">
              <div className="contests-title-row">
                <div className="contests-header">REGISTER!</div>
              </div>
              <div className="registration-container">
              {
                {
                  'PasswordOne': <PasswordOne step={step} handleStepSubmit={handleStepSubmit} />,
                  'PasswordTwo': <PasswordTwo step={step} handleStepSubmit={handleStepSubmit} password={password} />,
                  'PlayerName': <PlayerName step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'PlayerRealName': <PlayerRealName step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'PlayerStateProvince': <PlayerStateProvince step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'PlayerAddress': <PlayerAddress step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'PlayerCity': <PlayerCity step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'PlayerZipCode': <PlayerZipCode step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'PlayerPhone': <PlayerPhone step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'PlayerTwitter': <PlayerTwitter step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'PlayerNHC': <PlayerNHC step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'HowDidYouHear': <HowDidYouHear step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'YearStarted': <YearStarted step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'PlayerGender': <PlayerGender step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'PlayerAge': <PlayerAge step={step} handleStepSubmit={handleStepSubmit} userData={userData} />,
                  'Confirm': <PlayerConfirm step={step} handleStepSubmit={handleStepSubmit} userData={userData} />
                }[step]
              }
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

const PasswordOne = ({step, handleStepSubmit}) => {
  return (
    <div className="registration-details animate__animated animate__flipInX">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Email validated! Password time.</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            password: ''
          }}
          validate={values => {
            const errors = {};
            if (!values.password) {
              errors.password = 'Required';
            }
            else if (values.password.length < 6) {
              errors.password = 'Password is too short.  Minimum is 6 characters.'
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text">
                  <p>That was the hardest part. Now, please choose a password: 6 characters minimum. You do NOT need to put in all kinds of crazy characters.</p>
                </div>
                <div className="registration-text-field-container">
                  <label htmlFor="password"></label>
                  <Field type="password" name="password" placeholder="Please enter a password..." autoFocus={true} />
                  <ErrorMessage name="password" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div> 
                  <p>&nbsp;</p>
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PasswordTwo = ({step, handleStepSubmit, password}) => {
  return (
    <div className="registration-details animate__animated animate__flipInX">              
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Let&rsquo;s confirm that password!</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            password: ''
          }}
          validate={values => {
            const errors = {}
            if (!values.password) {
              errors.password = 'Required'
            }
            else if (values.password !== password) {
              errors.password = 'Passwords must match.'
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text">
                  <p>Please type in your password again. </p>
                </div>
                <div className="registration-text-field-container">
                  <label htmlFor="password"></label>
                  <Field type="password" name="password" placeholder="Re-enter that password..." autoFocus={true} />
                  <ErrorMessage name="password" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PasswordOne'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerName = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__jackInTheBox">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Choose your player name. Limit 18 characters (just like horse names!)</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            handle: userData.player.handle
          }}
          validate={values => {
            const errors = {};
            if (!values.handle) {
              errors.handle = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text">
                  <p>This is the name everyone will see on leaderboards. You will be able to change this later if you choose.</p>
                </div>
                <div className="registration-text-field-container">
                  <label htmlFor="handle"></label>
                  <Field type="text" name="handle" placeholder="Enter player name..." autoFocus={true} />
                  <ErrorMessage name="handle" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PasswordOne'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerRealName = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__rollIn">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Let’s get your real name.</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            first_name: userData.player.first_name,
            last_name: userData.player.last_name
          }}
          validate={values => {
            const errors = {};
            if (!values.first_name) {
              errors.first_name = 'Required';
            }
            if (!values.last_name) {
              errors.last_name = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text">
                  <p>To win any prizes, you have to be registered under your real name!</p>
                </div>
                <div className="registration-text-field-container">
                  <label htmlFor="first_name"></label>
                  <Field type="text" name="first_name" placeholder="First Name" autoFocus={true} />
                  <ErrorMessage name="first_name" component="div" />
                </div>
                <div className="registration-text-field-container">
                  <label htmlFor="last_name"></label>
                  <Field type="text" name="last_name" placeholder="Last Name" />
                  <ErrorMessage name="last_name" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerName'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerStateProvince = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__zoomInRight">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Please tell us the state or province you reside in.</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            state: userData.player ? userData.player.state : '',
            country: (userData.player && userData.player.country) ? userData.player.country : 'US'
          }}
          validate={values => {
            const errors = {};
            if ((values.country === 'US' || values.country === 'CA') && !values.state) {
              errors.state = 'Required';
            }
            if (!values.country) {
              errors.country = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text">
                  <p>Sport of Kings is free and legal in all states, and Canada!</p>
                </div>
                <div className="text-field-container">
                  <Field name="country" as="select" id="country" className="country-menu">
                    <CountryOptions />
                  </Field>
                  <ErrorMessage name="country" component="div" />
                </div>
                {(values.country === 'US' || values.country === 'CA') &&
                  <div className="registration-text-field-container">
                    <Field name="state" as="select" id="state" className="state-menu" autoFocus={true}>
                      <StateProvinceOptions country={values.country} />
                    </Field>
                    <ErrorMessage name="state" component="div" />
                  </div>
                }
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerRealName'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerAddress = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__zoomInRight">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Please provide your street address.</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            address1: userData.player.address1
          }}
          validate={values => {
            const errors = {};
            if (!values.address1) {
              errors.address1 = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text">
                  <p>We need street address. Only one account per person, gotta keep it legit.</p>
                </div>
                <div className="registration-text-field-container">
                  <Field type="text" name="address1" placeholder="Your Street Address" autoFocus={true} />
                  <ErrorMessage name="handle" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerStateProvince'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerCity = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__zoomInRight">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Please provide City...</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            city: userData.player.city
          }}
          validate={values => {
            const errors = {};
            if (!values.city) {
              errors.city = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text-field-container">
                  <Field type="text" name="city" placeholder="Your City" autoFocus={true} />
                  <ErrorMessage name="handle" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerAddress'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerZipCode = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__zoomInRight">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Zip Code</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            postal_code: userData.player.postal_code
          }}
          validate={values => {
            const errors = {};
            if (!values.postal_code) {
              errors.postal_code = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text-field-container">
                  <Field type="text" name="postal_code" placeholder="Your Postal Code" autoFocus={true} />
                  <ErrorMessage name="handle" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerCity'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerPhone = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__zoomInRight">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Please provide your phone number.</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            phone: userData.player.phone
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text">
                  <p>Look, this is optional, we are not going to call you. But what if you win something big and never get the email? We promise not to call unless it’s important. We hate getting calls too.</p>
                </div>
                <div className="registration-text-field-container">
                  <Field type="text" name="phone" placeholder="Your Phone Number" autoFocus={true} />
                  <ErrorMessage name="handle" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerZipCode'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerTwitter = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__zoomInRight">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Do you have a Twitter handle? (the @ is not necessary)</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            twitter_handle: userData.player.twitter_handle
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text">
                  <p>Leave blank if not. But we want to Tweet and give credit if you win.</p>
                </div>
                <div className="registration-text-field-container">
                  <Field type="text" name="twitter_handle" placeholder="Your Twitter Handle — no need for @" autoFocus={true} />
                  <ErrorMessage name="twitter_handle" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerPhone'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerNHC = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__zoomInRight">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">If You Have an NHC Tour Number, Enter it Here</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            nhc_tour_number: userData.player.nhc_tour_number,
            nhc_single_qualified: userData.player.nhc_single_qualified,
            nhc_double_qualified: userData.player.nhc_double_qualified
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="prefs-text">
                  <label htmlFor="nhc-tour-number">Your NHC Tour Number (Don’t have one? <a href="https://www.ntra.com/membership/?promo=SportofKings" target="_blank" >Join now!</a>)</label>
                </div>
                <div className="registration-text-field-container">
                  <Field type="text" id="nhc-tour-number" name="nhc_tour_number" placeholder="4- to 6-digit number" maxLength={6} autoFocus={true} />
                </div>
              </div>
              <div className="email-prefs-container">
                <div className="email-prefs-checkbox">
                  <Field type="checkbox" id="nhc_single_qualified" name="nhc_single_qualified" className="larger-checkbox" />
                  <label htmlFor="nhc_single_qualified"></label>
                </div>
                <div className="account-page-white-text">Check here if you have qualified for the 2022 NHC.</div>
              </div>
              <div className="email-prefs-container">
                <div className="email-prefs-checkbox">
                  <Field type="checkbox" id="nhc_double_qualified" name="nhc_double_qualified" className="larger-checkbox" />
                  <label htmlFor="nhc_double_qualified"></label>
                </div>
                <div className="account-page-white-text">Check if you have DOUBLE-qualified for the 2022 NHC. (And congrats!)</div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerTwitter'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const HowDidYouHear = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__bounceIn">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">Almost done! How did you hear about Sport of Kings?</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            how_did_you_hear: userData.player.how_did_you_hear,
            how_did_you_hear_comments: userData.player.how_did_you_hear_comments
          }}
          validate={values => {
            const errors = {};
            if (!values.how_did_you_hear) {
              errors.how_did_you_hear = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text">
                  <p>If not on the list below, type in Comments field, thanks!</p>
                </div>
                <div className="registration-text-field-container">
                  <Field name="how_did_you_hear" as="select" className="heard-menu" autoFocus={true} >
                    <option value="">Heard it from...</option>
                    <option value="cby">Canterbury Park Broadcast</option>
                    <option value="morningline">DRF Email</option>
                    <option value="racingformsite">DRF Website</option>
                    <option value="family">Family Member</option>
                    <option value="friend">Friend</option>
                    <option value="inthemoney">In the Money Player&rsquo;s Podcast</option>
                    <option value="jason-beem">Jason Beem Podcast</option>
                    <option value="ntra-email">NTRA Email</option>
                    <option value="ntrasite">NTRA Website</option>
                    <option value="ph">Public Handicapper Player</option>
                    <option value="sok-pod">Sport of Kings Podcast</option>
                    <option value="twitter">Twitter</option>
                    <option value="wom">Word of Mouth &ndash; Not sure</option>
                    <option value="other">If other, write in comments</option>
                  </Field>
                  <ErrorMessage name="handle" component="div" />
                  <div className="registration-text">
                    <label htmlFor="how_did_you_hear_comments">Comments (optional)</label>
                  </div>
                  <div className="registration-text-field-container">
                    <Field as="textarea" name="how_did_you_hear_comments" placeholder="Comments..." />
                  </div>
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerNHC'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const YearStarted = ({handleStepSubmit, userData}) => {
  const year = (new Date()).getFullYear()
  const years = Array.from(new Array(101), (val, index) => year - index)

  return (
    <div className="registration-details animate__animated animate__bounceIn">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">About what year did you start playing the horses?</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            year_started: userData.player.year_started
          }}
           validate={values => {
            const errors = {};
            if (!values.year_started) {
              errors.year_started = 'Required';
            }
            return errors;
          }}
         onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text-field-container">
                  <Field name="year_started" as="select" className="heard-menu" autoFocus={true} >
                  <option value="">Select Year...</option>
                    { years.map((year, index) =>
                      <option key={`year${index}`} value={year}>{year}</option>
                    )}
                  </Field>
                  <ErrorMessage name="handle" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'HowDidYouHear'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerGender = ({handleStepSubmit, userData}) => {
  return (
    <div className="registration-details animate__animated animate__bounceIn">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">What is your gender?</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            gender: userData.player.gender,
          }}
          validate={values => {
            const errors = {};
            if (!values.gender) {
              errors.gender = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text-field-container">
                  <Field name="gender" as="select" className="heard-menu" autoFocus={true} >
                    <option value="">Choose...</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Field>
                  <ErrorMessage name="handle" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'YearStarted'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerAge = ({handleStepSubmit, userData}) => {
  const year = (new Date()).getFullYear() - 18
  const years = Array.from(new Array(101), (val, index) => year - index)

  return (
    <div className="registration-details animate__animated animate__bounceIn">
      <div className="register1-button">
        <div className="registration-info-container">
          <div className="register-title-text">What is your year of birth?</div>
        </div>
      </div>
      <div className="registration-form-container">
        <Formik
          initialValues={{ 
            birth_year: userData.player.birth_year
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleStepSubmit(values, setSubmitting)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="account-text-field-container">
                <div className="registration-text-field-container">
                  <Field name="birth_year" as="select" className="heard-menu" autoFocus={true} >
                    <option value="">Select Birth Year...</option>
                    { years.map((year, index) =>
                      <option key={`year${index}`} value={year}>{year}</option>
                    )}
                  </Field>
                  <ErrorMessage name="handle" component="div" />
                </div>
              </div>
              <div className="save-changes-container">
                <div className="back-button"> 
                  <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerGender'}, false)}} />
                </div>
                <input type="submit" value="CONTINUE" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PlayerConfirm = ({ handleStepSubmit, handleAccountUpdate }) => {
  const [complete, setComplete] = useState(false)

  return complete ? (
    <Redirect to="/contests" />
  ) : (
      <div className="registration-details animate__animated animate__fadeInUp">              
        <div className="registration-form-container">
          <div className="register1-button">
            <div className="registration-info-container">
              <div className="register-title-text">Registration Complete!</div>
            </div>
          </div>
          <div className="account-text-field-container">
            <div className="registration-text-field-container">
              <p>Phew! We made it! Now you can <Link to="/contests">Start Playing</Link>, or continue with <Link to="/account">Account Details</Link> (yeah, we’d love to know more).</p><p>&nbsp;</p>
            </div>
          </div>
          <form action="/contests" method="get">
            <div className="save-changes-container">
              <div className="back-button"> 
                <input type="button" value="< Back" name="back" onClick={() => { handleStepSubmit({back: 'PlayerAge'}, false)}} />
              </div>
                <input type="submit" value="START PLAYING" autoFocus="true" />
            </div>
          </form>  
        </div>
      </div>
  )
}

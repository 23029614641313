import React from 'react';
import ReactDOM from 'react-dom';
import { ActionCableProvider } from 'react-actioncable-provider';
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import App from './App';

ReactDOM.render(
  <ActionCableProvider url={`${process.env.REACT_APP_API_WS_URL}`}>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </ActionCableProvider>,
  document.getElementById('root')
);
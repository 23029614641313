import React from 'react'
import { Link } from "react-router-dom";

export default function PicksButton({contest, className, handleEnterClick}) {
  let picksButton = ''
  if (contest.contest_type !== 'Series') {
    if (contest.entered && contest.upcoming) {
      picksButton = <div className={className}>PICKS COMING SOON</div>
    }
    else if (contest.entered) {
      let picksText = 'PICKS'
      if (className === 'outlined-button-make-picks') {
        picksText = 'MAKE/MODIFY PICKS'
      }
      picksButton = <Link to={`/contests/${contest.id}/picks`}><div className={className}>{picksText}</div></Link>
    }
    else if (contest.invitation_only) {
      picksButton = <div className={className}>PRIVATE CONTEST</div>
    }
    else if (contest.qualification_required) {
      picksButton = <div className={className}>MUST QUALIFY</div>
    }
    else if (!contest.finished) {
      picksButton = <div className={className} onClick={() => handleEnterClick(contest.id)}>ENTER</div>
    }
  }

  return picksButton
}

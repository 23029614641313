import React from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import Obfuscate from 'react-obfuscate';

export default function About() {
  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | About</title>
        <meta name="description" content="Sport of Kings history and mission." />
      </Helmet>
	<div className="content-wrapper">
	  <main>
	    <SOKSidebar />
	    <div className="main-homepage">
			<div className="contests-title-row">
			  <div className="homepage-header">About Sport of Kings</div>
			</div>
			<div className="general-text-wrapper">
				<div className="general-text">
					<div className="about-logo-container">
						<img src="/img/sport-of-kings-complete-logo-white-on-black.svg" className="sok-complete-logo" />
					</div>
					<h2>Mission</h2>
					<p>Sport of Kings mission is to grow the sport of horse racing by cultivating new horseplayers (and engaging current ones) with free contests and free past performances.
					</p>

					<h2>Business Inquiries</h2>
					<p>To tap into this source of new horseplayers, contact us at: <Obfuscate email="sponsorship@sportofkings.net" />
					</p>

					<h2>Removing the Time and Money Obstacle</h2>  
					<p>It takes time and money
					to become a horseplayer. Sport of Kings accelerates the learning process
					with free contests that run daily, weekly, and monthly. And Sport of Kings
					eliminates the financial obstacle by providing free PPs
					for every contest race. New horseplayers have the opportunity to learn the
					game without the expense. </p>

					<h2>Lightning in a Bottle</h2> 
					<p>Horseplayers know the thrill of picking a
					winner and hitting a race. To quote professional horseplayer Paul Matties,
					“When you hit a race, it feels like an accomplishment. Bottle that somehow.” And we want to
					bottle it. The way to do that is with free contests with free PPs. We’re
					giving new horseplayers a chance to catch lightning in a bottle and become
					successful horseplayers with no risk. And they might even win a prize. </p>

					<h2>Just Another Way to Win</h2>
					<p>The sport of horse racing is a mental challenge. Picking the winner feels like an accomplishment, and hitting a wager feels even better. Contests are just another way to win — another way to engage with the sport of horse racing. And with free contests there is absolutely no risk. Winning a free contest builds confidence in beginning horseplayers and sets them on the road to becoming regular horseplayers.
					</p>

				</div>
			</div>
	    </div>
	  </main>
	</div>
    </div>
  )
}

import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import SOKSidebar from './SOKSidebar'
import ReactMarkdown from 'react-markdown';
import { UserContext } from './App'
import { Formik, Form, Field } from 'formik';
import axios from 'axios';

export default function Event() {
  const [event, setEvent] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [registrationMessage, setRegistrationMessage] = useState('Loading...')
  let { eventId } = useParams();
  const { userData } = useContext(UserContext)

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}events/${eventId}`)
      .then(res => {
        console.log(res)
        setEvent(res.data)
      })
  }, [eventId])

  function handleSubmit(values, setSubmitting) {
    axios.post(`${process.env.REACT_APP_API_URL}events/${eventId}/register`, {
      registration: values
    }).then(res => {
      setRegistrationMessage('Your registration has been received. Thanks for signing up!')
      setSubmitting(false)
      setSubmitted(true)
    })
  }

  return (
    <div className="outer-wrapper">
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="main-homepage">
            <div className="contests-title-row">
              <div className="contests-header">{ event.name }</div>
            </div>
                      <div className="benefactors-intro-text">
                        <ReactMarkdown>{event.body}</ReactMarkdown>
                      </div>
            { !submitted && userData.loaded ? (
              <div className="registration-container">
                <div className="registration-details">
                  <div className="register1-button">
                    <div className="registration-info-container">
                      <div className="register-title-text">Sign up for this event here. You will be emailed a link to the Livestream 60 minutes and 15 minutes before it begins.</div>
                    </div>
                  </div>
                  <div className="account-wrapper">
                    <Formik
                      initialValues={{ 
                        email: userData.player ? userData.player.email : '',
                        first_name: userData.player ? userData.player.first_name : '',
                        last_name: userData.player ? userData.player.last_name : ''
                      }}
                      validate={values => {
                        const errors = {};
                        if (!values.email) {
                          errors.email = 'Required';
                        }
                        if (!values.first_name) {
                          errors.first_name = 'Required';
                        }
                        if (!values.last_name) {
                          errors.last_name = 'Required';
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values, setSubmitting)
                      }}
                    >
                      {({ errors, touched, isSubmitting }) => (
                        <Form>
                          <div className="account-text-field-container">
                            <div className="prefs-text">
                              <label htmlFor="first_name">First Name</label>
                            </div>  
                            <div className="registration-text-field-container">
                              <Field type="text" name="first_name" placeholder="Enter First Name..." />
                              {errors.first_name && touched.first_name ? (
                                <div>{errors.first_name}</div>
                              ) : null}
                            </div>
                           <div className="prefs-text">
                             <label htmlFor="first_name">Last Name</label>
                           </div>
                           <div className="registration-text-field-container">
                              <Field type="text" name="last_name" placeholder="Enter Last Name..." />
                              {errors.last_name && touched.last_name ? (
                                <div>{errors.last_name}</div>
                              ) : null}
                            </div>
                           <div className="prefs-text">
                              <label htmlFor="first_name">Email</label>
                           </div>
                            <div className="registration-text-field-container">
                              <Field type="email" name="email" placeholder="Enter email address..." />
                              {errors.email && touched.email ? (
                                <div>{errors.email}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="save-changes-container">
                            <input type="submit" value="REGISTER" />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            ) : (
              <div className="registration-container">
                <div className="registration-details">
                  <div className="register1-button">
                    <div className="registration-info-container">
                      <div className="register-title-text">{ registrationMessage }</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  )
}

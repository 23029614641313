import React, { useState, useContext } from 'react'
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import { Redirect } from "react-router-dom";
import { UserContext } from './App'
import { Formik, Form, Field } from 'formik';
import SOKSidebar from './SOKSidebar'
import Obfuscate from 'react-obfuscate';
import axios from 'axios';

export default function UserRegister() {
  const [registered, setRegistered] = useState(false)
  const [alreadyExists, setAlreadyExists] = useState(false)
  const { userData } = useContext(UserContext)

  function handleRegisterSubmit(values, setSubmitting) {
    const landingPage = localStorage.getItem('sportofkings.landingPage')
    values.sign_up_link = landingPage
    axios.post(`${process.env.REACT_APP_API_URL}players`, {
      player: values
    }).then(res => {
      // Shouldn't happen.
      setSubmitting(false)
    }).catch(error => {
      if (error.response && error.response.status === 401) {
        // We are expecting Rails to return a 401.  It cannot log the user in
        // yet, but it has sent them an email.
        alert('An email has been sent.  Please follow the link in that email to continue setting up your account.')
        setAlreadyExists(false)
        setRegistered(true)
      }
      else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.email && error.response.data.errors.email[0] === 'has already been taken') {
        setAlreadyExists(true)
      }
      else {
        alert('An unknown error occurred while registering your account.')
        setAlreadyExists(false)
      }
      setSubmitting(false)
    })
  }

  return (userData.token || registered) ? (
    <Redirect to="/" />
  ) : (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | Register</title>
        <meta name="description" content="Register once to be able to enter any contest for free." />
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="registration-account-column">
            <div className="contests-title-row">
              <div className="contests-header">REGISTER!</div>
            </div>
            <div className="registration-container">
              <div className="registration-details">
                <div className="register1-button">
                  <div className="registration-info-container">
                    <div className="register-title-text">Welcome to the Sport of Kings!</div>
                  </div>
                </div>
                <div className="registration-form-container">
                  <Formik
                    initialValues={{ 
                      email: ''
                    }}
                    validate={values => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = 'Required';
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      handleRegisterSubmit(values, setSubmitting)
                    }}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form>
                        <div className="account-text-field-container">
                          <div className="registration-text">
                            <p>Please enter your email address. We will send a confirmation email!</p>
                          </div>
                          <div className="registration-text-field-container">
                            <Field type="email" name="email" placeholder="Enter email address..." />
                            {errors.email && touched.email ? (
                              <div>{errors.email}</div>
                            ) : null}
                          </div>
                          { alreadyExists &&
                            <div className="registration-text">
                              <p> <span style={{color: "#CB0906"}}>An account with your email address already exists.</span>  Did you <Link to="/password">forget your password?</Link></p>
                            </div>
                          }
                          <div className="registration-text">
                            <p>If you do not receive the email please check your Promotions folder (Gmail) or your Spam folder. If you still cannot find it please contact us: <Obfuscate email="hello@sportofkings.net" /></p>
                          </div>
                        </div>
                        <div className="save-changes-container">
                          <div> 
                            <p>&nbsp;</p>
                          </div>
                          <input type="submit" value="CONTINUE" />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

import axios from 'axios';

export default function EnterClick(contestId, callable, agreed = false) {

  axios.post(`${process.env.REACT_APP_API_URL}contests/${contestId}/enter`, {
    agreed_to_entry_terms: agreed
  })
    .then(res => {
      alert('You have been entered for this contest.')
      callable(res.data)
    })
    .catch(error => {
      let message
      console.log(error)
      // 412 Precondition required: contest has entry terms.
      if (!agreed && error.response && error.response.status === 412) {
        message = error.response.data.errors.entry_terms
        if (window.confirm(message)) {
          EnterClick(contestId, callable, true)
        }
        else {
          alert('You have not been entered for the contest. If you change your mind please enter anytime.')
        }
      }
      else {
        if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.handle) {
          message = 'Please choose your player name to enter this contest.  To set your player name, click the "Account" link at the upper right and click "edit" next to "player name".'
        }
        else {
          message = 'Could not enter contest.'
          if (error.response && error.response.status === 401) {
            message = 'You must sign in or register to enter this contest.'
          }
        }
        alert(message)
      }
    })
}

import React, { useState, useEffect, useContext } from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import ContestCard from './ContestCard'
import EnterClick from './EnterClick'
import { UserContext } from './App'
import axios from 'axios';

export default function Contests() {
  const [contests, setContests] = useState([])
  const { userData } = useContext(UserContext)

   const handleEnterClick = (contestId) => {
    EnterClick(contestId, function(data) {
      setContests(contests.map((c) => {
        if (c.id === contestId) {
          c = data
        }
        return c
      }))
    })
  }

  useEffect(() => {
    if (userData.loaded) {
      axios.get(`${process.env.REACT_APP_API_URL}contests`)
        .then(res => {
          console.log(res.data)
          setContests(res.data)
        })
    }
  }, [userData])

  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | Contests</title>
        <meta name="description" content="Enter any of these horse racing contests now for free!" />
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar initialContests={contests} />
          <div className="contests-column">
            <div className="contests-title-row">
              <div className="contests-header">CONTESTS</div>
            </div>
            <ol className="contests-container">
              { contests.filter(contest => !contest.finished).sort((a, b) => {
                // Sort active contests ahead of upcoming.
                if (a.upcoming && !b.upcoming) return 1;
                if (!a.upcoming && b.upcoming) return -1;

                // Sort series contests below other contests.
                if (a.contest_type === 'Series' && b.contest_type !== 'Series') return 1;
                if (a.contest_type !== 'Series' && b.contest_type === 'Series') return -1;

                // Sort active contests by most recently started.
                if (!a.upcoming) {
                  return new Date(b.start_date) - new Date(a.start_date);
                }

                // Sort upcoming contests by soonest start date.
                return new Date(a.start_date) - new Date(b.start_date);
              }).map(contest =>
                <ContestCard key={contest.id} contest={contest} handleEnterClick={handleEnterClick} />
              )}
            </ol>
          </div>
        </main>
      </div>
    </div>
  )
}

import React, { useContext, useEffect } from 'react';
import { UserContext } from './App'
import { Link } from "react-router-dom";
import axios from 'axios';

export default function SOKNav() {
  const { userData, setUserData } = useContext(UserContext)

  useEffect(() => {
    const buttons = document.querySelectorAll('.navbar a')
    for (const button of buttons) {
      button.addEventListener('click', function(event) {
        document.querySelector('#nav-checkbox').checked = false;
      })
    }
  }, [userData])

  function handleLogoutSubmit(event) {
    event.preventDefault();
    axios.defaults.headers.common['Authorization'] = ''
    setUserData({ loaded: true, token: false, player: {} })
  }

  return (
    <div className="nav-outer-wrapper">
      <div className="navbar">
        <input id="nav-checkbox" type="checkbox" />
        <div className="logo-container">
          <Link to="/">
              <img className="sok-logo" src="/img/sok-logo-horseless.svg" alt="Sport of Kings Logo" />
              <img className="sok-logo-no-stars" src="/img/sok-logo-no-stars.svg" alt="Sport of Kings Logo" />
          </Link>
        </div>
        <label htmlFor="nav-checkbox" className="menu-button">
          <img className="hamburger-menu" src="/img/hamburger.svg" alt="Mobile Menu" />
        </label>
        <label htmlFor="nav-checkbox" className="close-button">
          <img src="/img/close.svg" alt="close" />
        </label>
        <nav>
          <ul>
            <li className="just-free-pps">
              <div className="just-free-pps"><Link to="/past-performances">Free PPs</Link></div>
            </li>
            <li className="button-pps">
              <div className="button-pps"><Link to="/past-performances">Free Past Performances</Link></div>
            </li>
            <li>
              <div><Link to="/standings">Standings</Link></div>
            </li>
            <li>
              <div><Link to="/contests">Contests</Link></div>
            </li>
            <li>
              <div><Link to="/odds">Odds</Link></div>
            </li>
            <li>
              <div><Link to="/make-picks">Make Picks</Link></div>
            </li>
            {userData.token ? (
              <>
                <li>
                  <div><Link to="/account">Account</Link></div>
                </li>
                <li>
                <div className="nav-signup">
                  <span onClick={handleLogoutSubmit}>
                    Log Out
                  </span>
                </div>
                </li>
              </>
            ) : (
              <>
                <li>
                  <div className="nav-register">
                    <Link to="/register">Register</Link>
                  </div>
                </li>
                <li>
                  <label htmlFor="signin-checkbox">
                    <div className="nav-signup">
                      Sign In
                    </div>
                  </label>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </div>
  )
}

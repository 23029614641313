import React from 'react'
import Moment from 'moment';

export default function UserPicks({contest, picks, handleRaceClick}) {

  function getPickName(contestRace) {
    const pick = picks.find(pick => pick.contest_race_id === contestRace.id && pick.delta === 1)
    return pick && pick.horse ? pick.horse.name : contestRace.race.betting_open ? '- TBD -' : '- No Pick -'
  }

  return (
    <div className="fourth-column">
      { contest && contest.ad_square_url ?
        <div className="make-picks-advertisement">
          <a href={contest.ad_destination_url}>
            <img src={contest.ad_square_url} />
          </a>
        </div>
      :
        <div className="make-picks-advertisement-empty">Your ad here. </div>
      }
      <div className="your-picks-column">             
        <div className="your-picks-wrapper">
          <div className="your-picks1-button">
            <div className="your-picks-button-container">
              <div className="your-picks-heading">Your Picks</div>
              <div className="carat">
                <img src="/img/white-carat-down.svg" alt="" className="changeCarat" />
              </div>
            </div>
          </div>
          <div className="your-picks-reveal">
            <div className="your-picks-row-header">
              <div className="your-picks-event-header">#</div>
              <div className="your-picks-race-header">Race <span className="light-black-text">(Date)</span></div>
              <div className="your-picks-horse-header">Horse <span className="light-black-text">(click to change)</span></div>
            </div>
            <ol className="player-picks">
              { contest.contest_races && contest.contest_races.map(contestRace => 
                <li
                  key={contestRace.id}
                  className="your-picks-row"
                  onClick={() => handleRaceClick(contestRace.race.id, contestRace.delta)}
                >
                  <div className="your-picks-race-number">{contestRace.delta}</div>
                  <div className="your-picks-race-track-date">{contestRace.race.short_name} <span className="light-black-text">({Moment(contestRace.race.race_starts_at).format('M/D')})</span></div>
                  <div className="your-picks-horse-name"><a href="#" className="live-race-pick">{getPickName(contestRace)}</a></div>
                </li>
              )}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useContext } from 'react'
import { Redirect } from "react-router-dom";
import { UserContext } from './App'
import { Formik, Form, Field } from 'formik';
import SOKSidebar from './SOKSidebar'
import axios from 'axios';

export default function UserPassword() {
  const [sent, setSent] = useState(false)
  const { userData } = useContext(UserContext)

  function handleSubmit(values, setSubmitting) {
    axios.get(`${process.env.REACT_APP_API_URL}forgot_password?email=${values.email}`
    ).then(res => {
      setSubmitting(false)
      setSent(true)
    }).catch(error => {
      setSubmitting(false)
    })
  }

  return (userData.token) ? (
    <Redirect to="/" />
  ) : (
    <div className="outer-wrapper">
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="registration-account-column">
            <div className="contests-title-row">
              <div className="contests-header">Forgot your password?</div>
            </div>
            { !sent ? (
              <div className="registration-container">
                <div className="registration-details">
                  <div className="register1-button">
                    <div className="registration-info-container">
                      <div className="register-title-text">Hey, it happens to the best of us.</div>
                    </div>
                  </div>
                  <div className="registration-form-container">
                    <Formik
                      initialValues={{ 
                        email: ''
                      }}
                      validate={values => {
                        const errors = {};
                        if (!values.email) {
                          errors.email = 'Required';
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values, setSubmitting)
                      }}
                    >
                      {({ errors, touched, isSubmitting }) => (
                        <Form>
                          <div className="account-text-field-container">
                            <div className="registration-text">
                              <p>Enter your email address and we will send you a link to reset your password.</p>
                            </div>
                            <div className="registration-text-field-container">
                              <Field type="email" name="email" placeholder="Enter email address..." />
                              {errors.email && touched.email ? (
                                <div>{errors.email}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="save-changes-container">
                            <input type="submit" value="SUBMIT" />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            ) : (
              <div className="registration-container">
                <div className="registration-details">
                  <div className="register1-button">
                    <div className="registration-info-container">
                      <div className="register-title-text">We've sent you an email.  Please check your inbox.</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  )
}

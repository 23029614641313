import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from './App'
import { Link } from "react-router-dom";
import PicksButton from './PicksButton'
import EnterClick from './EnterClick'
import axios from 'axios';
import $ from 'jquery'

export default function SOKSidebar({initialContests, handleTogglePicks = false, setEntriesChanged = false}) {
  const [contests, setContests] = useState([])
  const { userData } = useContext(UserContext)

  useEffect(() => {
    $('.side-nav-contest-button').off('click');
    $('.side-nav-contest-button').on('click', function(e) {
      if ($(this).find('.changeCarat').attr('src') === '/img/white-carat-down.svg') {
        $(this).find('.changeCarat').attr('src', '/img/white-carat-up.svg');
      }
      else {
        $(this).find('.changeCarat').attr('src', '/img/white-carat-down.svg');
      }
      $(this).next('.side-nav-contest-reveal').slideToggle();
    });
  }, [contests])

  useEffect(() => {
    if (initialContests) {
      setContests(initialContests)
    }
    else {
      axios.get(`${process.env.REACT_APP_API_URL}contests`)
        .then(res => {
          setContests(res.data)
        })
    }
  }, [initialContests, userData])

  function handleEnterClick(contestId) {
    EnterClick(contestId, function(data) {
      setContests(contests.map((c) => {
        if (c.id === contestId) {
          c = data
        }
        return c
      }))
      if (setEntriesChanged) {
        setEntriesChanged(new Date().getTime())
      }
    })
  }

  return (
    <div className="side-nav-buttons">{/*
      { handleTogglePicks && userData.token && (
        <div className="contest-title">
          <div className="contest1-button">
            <div className="contest-button-container" onClick={handleTogglePicks}>
              <div className="contest-title-text">See Your Selections Alongside Standings</div>
            </div>
          </div>
        </div>
      )}
      */}<div className="contest-title">
        <div className="random-button">
          <a href="https://player.vimeo.com/video/444006799" target="_blank">
            <div className="random-button-container">
              <div className="random-text">How This Site Works!</div>
            </div>
          </a>
        </div>
      </div>
      <div className="side-nav-heading-ital">Active Contests</div>
      <div className="side-nav-groups-of-contests-wrapper">
        { contests.filter(contest => !contest.upcoming && !contest.finished).sort((a, b) => {
          if (a.contest_type === 'Series' && b.contest_type !== 'Series') return 1;
          if (a.contest_type !== 'Series' && b.contest_type === 'Series') return -1;
          else return new Date(b.start_date) - new Date(a.start_date)
        }).map(contest =>
          <SOKSidebarContestCard contest={contest} handleEnterClick={handleEnterClick} key={contest.id} />
        )}
      </div>
      <div className="side-nav-heading-ital">Upcoming Contests</div>
        <div className="side-nav-groups-of-contests-wrapper">
          { contests.filter(contest => contest.upcoming).sort((a, b) => new Date(a.start_date) - new Date(b.start_date)).map(contest =>
            <SOKSidebarContestCard contest={contest} handleEnterClick={handleEnterClick} key={contest.id} />
          )}
        </div>
      <div className="side-nav-heading-ital">Finished Contests</div>
      <div className="side-nav-groups-of-contests-wrapper">
        { contests.filter(contest => contest.finished).slice(0, 6).map(contest => 
          <SOKSidebarContestCard contest={contest} handleEnterClick={handleEnterClick} key={contest.id} />
        )}
      </div>
    </div>
  )
}

const SOKSidebarContestCard = ({contest, handleEnterClick}) => {
  return (
    <div className="side-nav-button-wrapper">
      <div className="side-nav-contest-button">
        <div className="side-nav-button-container">
          <div className="side-nav-text">{contest.name}</div>
          <div className="side-nav-carat-container"><img src="/img/white-carat-down.svg" alt="" className="side-nav-carat changeCarat" /></div>
        </div>
      </div>
      <div className="side-nav-contest-reveal" style={{ display: 'none' }}>
        <div className="side-nav-sub-button-container">
          <Link to={`/contests/${contest.id}`}>
            <div className="outlined-button-smaller">Info</div>
          </Link>
          { !contest.upcoming &&
            <Link to={`/contests/${contest.id}/standings`}>
              <div className="outlined-button-smaller">Standings</div>
            </Link>
          }
          { (!contest.qualification_required || contest.entered) && contest.contest_type !== 'Series' &&
            <PicksButton contest={contest} handleEnterClick={handleEnterClick} className='outlined-button-smaller' />
          }
          { contest.upcoming && contest.invitation_only &&
            <Link to={`/contests/${contest.id}/standings`}>
              <div className="outlined-button-smaller">Contestants</div>
            </Link>
          }
          { contest.upcoming && contest.qualification_required && !contest.invitation_only &&
            <Link to={`/contests/${contest.id}/standings`}>
              <div className="outlined-button-smaller">Qualifiers</div>
            </Link>
          }
        </div>                  
      </div>
    </div>
  )
}

import React from 'react'
import {Helmet} from "react-helmet";
import SOKSidebar from './SOKSidebar'
import Obfuscate from 'react-obfuscate';

export default function Benefactors() {
  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | Benefactors</title>
        <meta name="description" content="Sport of Kings thanks our 300+ early benefactors for kickstarting this website!" />
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar />
          <div className="main-homepage">
            <div className="contests-title-row">
              <div className="homepage-header">Sport of Kings Benefactors</div>
            </div>
            <div className="benefactors-intro-text">
              <div className="about-logo-container">
                <img src="/img/sport-of-kings-complete-logo-white-on-black.svg" className="sok-complete-logo" />
              </div>
              <p>We have ongoing and one-time financial backers. If you would like to add your name to this list <a href="https://www.patreon.com/sportofkings" target="_blank">go here for a monthly contribution,</a> and <a href="https://checkout.square.site/pay/d71d0c46d426446c9df8de19dd04c6f1
" target="_blank">here for a one-time contribution.</a> Thank you for your support!</p>

              <h2>Ongoing Benefactors</h2>
              <p>The following people (alphabetized by first name) are ongoing financial contributors to Sport of Kings. They keep the site going!</p>
               <div className="benefactors">
                <ul>
                    <li>Alan Levitt</li>
                    <li>Albert Denton</li>
                    <li>Andrew Norris</li>
                    <li>Ann McCurdy</li>
                    <li>Anthony Giordano</li>
                    <li>Anthony V Zanardi</li>
                    <li>Arthur Guglielmo</li>
                    <li>Ben Miller</li>
                    <li>Bill Holobowski</li>
                    <li>Bill Steadman</li>
                    <li>Billie Bolch</li>
                    <li>BJ Letourneau</li>
                    <li>Bob Bandzwolek</li>
                    <li>Bob Lester</li>
                    <li>Brad Sherwood</li>
                    <li>Brenda D'Angelo</li>
                    <li>Brett Wilburn</li>
                    <li>Brian Black</li>
                    <li>Brooks Schuler</li>
                    <li>Bruce Francisco</li>
                    <li>Bruce Meyer</li>
                    <li>Bruce Williams</li>
                    <li>Cara Yarusso</li>
                    <li>Charles Lucas</li>
                    <li>Charles Stadelmann</li>
                    <li>Cheryll Frank</li>
                    <li>Christopher Coleman</li>
                    <li>Christopher Goodall</li>
                    <li>Christopher Olsson</li>
                    <li>Collin M Baird</li>
                    <li>Craig Allison</li>
                    <li>Dan Donnelly</li>
                    <li>Dan Piazza</li>
                    <li>Daniel Duran</li>
                    <li>Daniel Kuchta</li>
                    <li>Daniel Pastalaniec</li>
                    <li>Danna Bohnhoff</li>
                    <li>Dave & Helen Edwards</li>
                    <li>David Cifonelli</li>
                    <li>David Conrad</li>
                    <li>David Matheny</li>
                    <li>Dennis Clark</li>
                    <li>Dennis Decauwer</li>
                    <li>Dennis Erwood</li>
                    <li>Desmond Riley</li>
                    <li>Dianne Marie Gray</li>
                    <li>Don Royce</li>
                    <li>Don Speaks</li>
                    <li>Doug Hargus</li>
                    <li>Dwight Nuclar</li>
                    <li>Ed Prosser</li>
                    <li>Eduardo Borrego</li>
                    <li>Ellis Starr</li>
                    <li>Eric Kurzhal</li>
                    <li>Eugene Davenport</li>
                    <li>Francis Capitanio</li>
                    <li>Frank Perri</li>
                    <li>Fred Christ</li>
                    <li>Geoffrey Schutt</li>
                    <li>George Howard</li>
                    <li>George Sutch</li>
                    <li>Gerald Luty</li>
                    <li>Greg Bentz</li>
                    <li>Greg DuBos</li>
                    <li>Greg Laurin</li>
                    <li>Guy Battinieri</li>
                    <li>Harris Factor</li>
                    <li>Irina Platonova</li>
                    <li>Jack Luty</li>
                    <li>James Bernard</li>
                    <li>Jamie Bulnes</li>
                    <li>Jeff Halvorson</li>
                    <li>Jeffrey Amster</li>
                    <li>Jeffrey Turkel</li>
                    <li>Jim Davey</li>
                    <li>Jim Templin</li>
                    <li>Jimmy Wells</li>
                    <li>Joe Eckert</li>
                    <li>Joe Kenny</li>
                    <li>Joe Pas</li>
                    <li>John Bevacqua</li>
                    <li>John Criscuolo</li>
                    <li>John Dickson</li>
                    <li>John Esterbrook</li>
                    <li>John Gaspar</li>
                    <li>John Kamenar</li>
                    <li>John Meyer</li>
                    <li>John Smith</li>
                    <li>John Zwadyk</li>
                    <li>Joseph Burke</li>
                    <li>Joseph Costello</li>
                    <li>Joseph Kenny</li>
                    <li>Julie Loboyko</li>
                    <li>Keith Carter</li>
                    <li>Keith Nodskov</li>
                    <li>Kevin Kilroy</li>
                    <li>Leo Swider</li>
                    <li>Lisa Andres</li>
                    <li>Lorne Weiss</li>
                    <li>Marcus Casey</li>
                    <li>Mark Gluckstern</li>
                    <li>Matthew Sargent</li>
                    <li>Michael Bryant</li>
                    <li>Michael Chilson</li>
                    <li>Michael Emerson</li>
                    <li>Michael Illies</li>
                    <li>Michael Staicer</li>
                    <li>Mike Koegler</li>
                    <li>Mike Meyers</li>
                    <li>Mike Yarusso</li>
                    <li>Mitchell Smith</li>
                    <li>Nancy Spence</li>
                    <li>NCPGahoo</li>
                    <li>Neal Metzger</li>
                    <li>Nick Seevers</li>
                    <li>Norman Jaillet</li>
                    <li>Pat Gianforte</li>
                    <li>Patrick Christianson</li>
                    <li>Patrick Ray</li>
                    <li>Paul Aussenhoffer</li>
                    <li>Paul Calia</li>
                    <li>Paul Langley</li>
                    <li>Paul Playe</li>
                    <li>Paul Rivera</li>
                    <li>Paul Roth</li>
                    <li>Paul Weizer</li>
                    <li>Pete Rogers</li>
                    <li>Phil Adams</li>
                    <li>Ralph Lough</li>
                    <li>Ray Arsenault</li>
                    <li>Rich Loftus</li>
                    <li>Richard Goodall</li>
                    <li>Rick Cornwell</li>
                    <li>Rick Hartman</li>
                    <li>Rick William Mowery</li>
                    <li>Robert Carlson</li>
                    <li>Robert Clarke</li>
                    <li>Robert Dean Roberts</li>
                    <li>Robert Masterton</li>
                    <li>Robert Phillips</li>
                    <li>Robert Schwartz</li>
                    <li>Robert Tacher</li>
                    <li>Ron Suttles</li>
                    <li>Sally Spencer</li>
                    <li>Salvatore Iovennio</li>
                    <li>Samuel Alaya</li>
                    <li>Samuel Popkoff</li>
                    <li>Scott Fitzgerald</li>
                    <li>Scott McGovern</li>
                    <li>Shawn Heron</li>
                    <li>Stephen Berdar</li>
                    <li>Stephen Tracy</li>
                    <li>Steve Hendricks</li>
                    <li>Steve Irwin</li>
                    <li>Steve Manner</li>
                    <li>Steven Banks</li>
                    <li>Stuart Hoff</li>
                    <li>Susan R. Taylor</li>
                    <li>Susan Ruth Taylor</li>
                    <li>Terrence Severson</li>
                    <li>Thomas Cagle</li>
                    <li>Thomas Elberson</li>
                    <li>Thomas Hillesland</li>
                    <li>Tim Betthauser</li>
                    <li>Tim Mazanec</li>
                    <li>Tom O’Brien</li>
                    <li>Tony Lavorato</li>
                    <li>William Artz</li>
                    <li>William Clay Holmes</li>
                    <li>William Ender</li>
                    <li>William Epp</li>
                    <li>William Mathews</li>
                    <li>William McGhee</li>
                    <li>Zachary Agamenoni</li>
                </ul>
               </div>     

             <h2>Original Benefactors</h2>
              <p>The following people (alphabetized by first name) contributed financially to Sport of Kings in its first year. We could not have done it without them!</p>


            <div className="benefactors">
              <ul>
                  <li>Adam Coti</li>
                  <li>Al Hartman</li>
                  <li>Alex Jones</li>
                  <li>Alfred R Rodriguez</li>
                  <li>Alistair B Wallbaum</li>
                  <li>Allen Rosenblum</li>
                  <li>Ambrogino Buttitta</li>
                  <li>Andres Villanueva</li>
                  <li>Andrew Graziani</li>
                  <li>Andrew J. Norris</li>
                  <li>Andy Muhlada</li>
                  <li>Andy Schnell</li>
                  <li>Anne Faulconer</li>
                  <li>Anthony Vaughan</li>
                  <li>Art Thompson</li>
                  <li>Arthur Guglielmo</li>
                  <li>Ben Miller</li>
                  <li>Bill Holobowski</li>
                  <li>Bill McKinney</li>
                  <li>Bill Zvara</li>
                  <li>Blain Bovee</li>
                  <li>Bob Lester</li>
                  <li>Brad Sherwood</li>
                  <li>Bradley Anderson</li>
                  <li>Bradley Boyer</li>
                  <li>Brandon Skop</li>
                  <li>Brett A Taylor</li>
                  <li>Brian Gallagher</li>
                  <li>Brian Tibbels</li>
                  <li>Bruce Francisco</li>
                  <li>C.J. Arrigo</li>
                  <li>Cara Yarusso</li>
                  <li>Cecil Leacock</li>
                  <li>Charles Cruz</li>
                  <li>Charles Luty</li>
                  <li>Charles Petraitis</li>
                  <li>Cheryl Macias</li>
                  <li>Christian Parker</li>
                  <li>Christopher Coleman</li>
                  <li>Christopher Laport</li>
                  <li>Christopher Larmey</li>
                  <li>Christopher Olsson</li>
                  <li>D. Marie Gray</li>
                  <li>Dale McCrossen</li>
                  <li>Dan Piazza</li>
                  <li>Dane R Moore</li>
                  <li>Daniel Camoro</li>
                  <li>Daniel Duran</li>
                  <li>Daniel Golec</li>
                  <li>Daniel Kuchta</li>
                  <li>Daniel Pastalaniec</li>
                  <li>Daryl Ebert</li>
                  <li>Dave Edwards</li>
                  <li>Dave Giannotti</li>
                  <li>Dave Nelson</li>
                  <li>Dave Schiapo</li>
                  <li>Dave Simmons</li>
                  <li>Dave Walker</li>
                  <li>David Becker</li>
                  <li>David Matheny</li>
                  <li>David Molique</li>
                  <li>Dean Freeman</li>
                  <li>Dell Yount</li>
                  <li>Dennis Clark</li>
                  <li>Dennis Decauwer</li>
                  <li>Dennis Erwood</li>
                  <li>Desmond Riley</li>
                  <li>Don Monich</li>
                  <li>Don Royce</li>
                  <li>Donald March</li>
                  <li>Douglas Latter</li>
                  <li>Drake Rogers</li>
                  <li>Dwight Frey</li>
                  <li>Ed Prosser</li>
                  <li>Eric Kurzhal</li>
                  <li>Eric Solomon</li>
                  <li>Frank D&rsquo;angelo</li>
                  <li>Frank Worm</li>
                  <li>Franz A Bronnimann</li>
                  <li>Fred Rezler</li>
                  <li>Fred Valerio</li>
                  <li>Frederick C Hardt</li>
                  <li>Gary Gristick</li>
                  <li>Gary Levinson</li>
                  <li>Gene Davenport</li>
                  <li>Geoff Schutt</li>
                  <li>George E Meacham</li>
                  <li>George Howard</li>
                  <li>Gerald Luty</li>
                  <li>Gil Nishina</li>
                  <li>Grace McCue</li>
                  <li>Greg Ritza</li>
                  <li>Greg Waddell</li>
                  <li>Gregory Bentz</li>
                  <li>Gregory Harmon</li>
                  <li>Gregory Kuehl</li>
                  <li>Harris G Factor</li>
                  <li>Hector R Irizarry</li>
                  <li>Helen Edwards</li>
                  <li>Henry Wessel</li>
                  <li>Ian Meyers</li>
                  <li>Ian Wilson</li>
                  <li>Irradiatedmeat</li>
                  <li>Jack Fricano</li>
                  <li>Jack Mansour</li>
                  <li>Jack Pentifallo</li>
                  <li>Jack Reid Fain</li>
                  <li>James Bryant Jr</li>
                  <li>James Hall</li>
                  <li>James Jairaffo</li>
                  <li>James Kopera</li>
                  <li>James M. Hartford</li>
                  <li>James McKenna</li>
                  <li>James Somerville</li>
                  <li>James Templin III</li>
                  <li>Jamie Michelson</li>
                  <li>Jason Harp</li>
                  <li>Jason M Perlmutter</li>
                  <li>Jeff G Valle</li>
                  <li>Jeff Halvorson</li>
                  <li>Jeff Richeson</li>
                  <li>Jeff Sleed</li>
                  <li>Jeffery Nuss</li>
                  <li>Jeffrey Turkel</li>
                  <li>Jeffrey W Bowman</li>
                  <li>Jeremy Schupp</li>
                  <li>Jerry Grady</li>
                  <li>Jim Crea</li>
                  <li>Jim Goodman</li>
                  <li>Jim Heet</li>
                  <li>Joe Fraser</li>
                  <li>Joe Johnson</li>
                  <li>Joe Rocha</li>
                  <li>Joel Glassman</li>
                  <li>John Cappalla</li>
                  <li>John Carr</li>
                  <li>John Chapell</li>
                  <li>John Coleman</li>
                  <li>John Criscuolo</li>
                  <li>John D Muller</li>
                  <li>John Dickson</li>
                  <li>John Dobrzynski</li>
                  <li>John E Maskel</li>
                  <li>John Herko</li>
                  <li>John Martin</li>
                  <li>John Millili</li>
                  <li>John P Reeves</li>
                  <li>John Patrick Jellineck</li>
                  <li>John Zwadyk</li>
                  <li>Jonathan Cummings</li>
                  <li>Joseph Ambrosio</li>
                  <li>Joseph Burke</li>
                  <li>Joseph Eckert</li>
                  <li>Joseph Kenny</li>
                  <li>Joseph Murphy</li>
                  <li>Josh Goodling</li>
                  <li>Judith Berube</li>
                  <li>Judy Kirkendall</li>
                  <li>Karl Stoicheff</li>
                  <li>Keith Pyles</li>
                  <li>Kelly Lawless</li>
                  <li>Ken Badawy</li>
                  <li>Ken Gribek</li>
                  <li>Ken Seeman</li>
                  <li>Kenny Doyle</li>
                  <li>Kent E Crossley</li>
                  <li>Kent Sorber</li>
                  <li>Kerry Lee</li>
                  <li>Kevin Harrell</li>
                  <li>Kirk Drane</li>
                  <li>Landis Ogburn</li>
                  <li>Larry Farmer</li>
                  <li>Larry Hall</li>
                  <li>Lee Emery</li>
                  <li>Leo Swider</li>
                  <li>Leonard Dodson</li>
                  <li>Leonard Macias</li>
                  <li>Leonard Vlahos</li>
                  <li>Linda Rodriguez</li>
                  <li>Lorne Weiss</li>
                  <li>Louis Dwight Peterson</li>
                  <li>Louis Mazzarelli</li>
                  <li>Louise McLaurin</li>
                  <li>Malcolm Chun</li>
                  <li>Mark C. Lowe</li>
                  <li>Mark Gluckstern</li>
                  <li>Matt Mullins</li>
                  <li>Matthew Bickey</li>
                  <li>Meryl Randman</li>
                  <li>Michael Beychok</li>
                  <li>Michael Caposio</li>
                  <li>Michael Carson</li>
                  <li>Michael Clair</li>
                  <li>Michael E Correia</li>
                  <li>Michael Falter</li>
                  <li>Michael J Myers Iii</li>
                  <li>Michael Kappel</li>
                  <li>Michael Labriola</li>
                  <li>Michael Lagrossa</li>
                  <li>Michael Murphy</li>
                  <li>Michael Pruzinsky</li>
                  <li>Michael Tomatz</li>
                  <li>Michael Watkins</li>
                  <li>Michael Webb</li>
                  <li>Michelle McSweeney</li>
                  <li>Mike Albano</li>
                  <li>Mike Capalbo</li>
                  <li>Mike Coutu</li>
                  <li>Mike Feltenberger</li>
                  <li>Mike Yarusso</li>
                  <li>Nadine Burr</li>
                  <li>Nick Schultz</li>
                  <li>Patrick Dugan</li>
                  <li>Patrick J. Gianforte</li>
                  <li>Patrick McDade</li>
                  <li>Patrick Ray</li>
                  <li>Paul Andry</li>
                  <li>Paul Aussenhofer</li>
                  <li>Paul Bradley</li>
                  <li>Paul Dummel</li>
                  <li>Paul Emery</li>
                  <li>Paul I Weizer</li>
                  <li>Paul Jensen</li>
                  <li>Paul Langley</li>
                  <li>Paul Mikos</li>
                  <li>Paul Playe</li>
                  <li>Peter Dresens</li>
                  <li>Peter Martine</li>
                  <li>Phil Nowlan</li>
                  <li>Phillip Johnson</li>
                  <li>Quentin Fleurat</li>
                  <li>R Dean Roberts</li>
                  <li>Ralph Lough</li>
                  <li>Ray A Sharpe</li>
                  <li>Ray Arsenault</li>
                  <li>Ray Davis</li>
                  <li>Ray Sharpe</li>
                  <li>Rhonda Holland</li>
                  <li>Richard A Guzlas</li>
                  <li>Richard Bennett</li>
                  <li>Richard Favire</li>
                  <li>Richard Heffner</li>
                  <li>Richard Loughrey</li>
                  <li>Robert Carlson</li>
                  <li>Robert Clarke</li>
                  <li>Robert Fleming</li>
                  <li>Robert Hahn</li>
                  <li>Robert J Meek</li>
                  <li>Robert P Jarwin</li>
                  <li>Robert Pearsall</li>
                  <li>Robert Randisi</li>
                  <li>Robert Tacher</li>
                  <li>Robert Wall</li>
                  <li>Robin D. Buser</li>
                  <li>Robin Krug</li>
                  <li>Roger Welch</li>
                  <li>Ron Richardson</li>
                  <li>Ron Salerno</li>
                  <li>Ronald W Reisner</li>
                  <li>Ronnie Ogle</li>
                  <li>Ross Gallo</li>
                  <li>Russell Miller</li>
                  <li>Sally Spencer</li>
                  <li>Salvatore Camilleri</li>
                  <li>Salvatore Iovieno</li>
                  <li>Scott Allen</li>
                  <li>Scott Coles</li>
                  <li>Scott Eyraud</li>
                  <li>Scott Fitzgerald</li>
                  <li>Scott Rea</li>
                  <li>Sinatra Jeter</li>
                  <li>Stephen Berdar</li>
                  <li>Stephen McNatton</li>
                  <li>Stephen Mencik</li>
                  <li>Stephen Tracy</li>
                  <li>Stephen Wolfson</li>
                  <li>Steve Sorohan</li>
                  <li>Steve Stange</li>
                  <li>Steven Banks</li>
                  <li>Steven Czerwinski</li>
                  <li>Stuart Hoff</li>
                  <li>Sue Cavanagh</li>
                  <li>Susan Peers</li>
                  <li>Susan Ruth Taylor</li>
                  <li>Terrence Severson</li>
                  <li>Terri Bagley</li>
                  <li>Terry Hay</li>
                  <li>Terry L. Peacock</li>
                  <li>Thomas Barone</li>
                  <li>Thomas Cagle</li>
                  <li>Thomas Kappel</li>
                  <li>Thomas Kutchenriter</li>
                  <li>Thomas Nicoletti</li>
                  <li>Thomas Remily</li>
                  <li>Thomas Riccobono</li>
                  <li>Thomas Rittenhouse</li>
                  <li>Thomas S McHugh</li>
                  <li>Tim Carey</li>
                  <li>Tim Mazanec</li>
                  <li>Timothy Braaten</li>
                  <li>Timothy F. Hegarty</li>
                  <li>Tom O&rsquo;Brien</li>
                  <li>Tom Ragsdale</li>
                  <li>Tom Troeller</li>
                  <li>Tyler Whisman</li>
                  <li>Vincent Brown</li>
                  <li>Vincent Mazzotta</li>
                  <li>Walter Dunbar</li>
                  <li>Wayne Sadowski</li>
                  <li>Wes Sweat</li>
                  <li>William Cook</li>
                  <li>William Duncliffe</li>
                  <li>William Ender</li>
                  <li>William Epp</li>
                  <li>William McGhee</li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

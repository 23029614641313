import React, { useState, useEffect, useContext } from 'react'
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import SOKSidebar from './SOKSidebar'
import EnterClick from './EnterClick'
import ContestEntryCard from './ContestEntryCard'
import { UserContext } from './App'
import axios from 'axios';

export default function MakePicks() {
  const [contests, setContests] = useState([])
  const [entriesChanged, setEntriesChanged] = useState(0)
  const { userData } = useContext(UserContext)

  useEffect(() => {
    if (userData.token) {
      axios.get(`${process.env.REACT_APP_API_URL}contests?status=active_not_upcoming&include=contest_races`)
        .then(res => {
          setContests(res.data)
        })
    }
  }, [userData, entriesChanged])

  function handleEnterClick(contestId) {
    EnterClick(contestId, function(data) {
      setContests(contests.map((c) => {
        if (c.id === contestId) {
          c = data
        }
        return c
      }))
    })
  }

  return (
    <div className="outer-wrapper">
      <Helmet>
        <title>Sport of Kings | Make Picks</title>
        <meta name="description" content="Make picks for any live contest now. To change picks, click off horse you have selected and then click horse you want." />
      </Helmet>
      <div className="content-wrapper">
        <main>
          <SOKSidebar setEntriesChanged={setEntriesChanged} />
          <div className="main-make-picks">
            <div className="make-picks-column">
              <div className="contests-title-row">
                <div className="make-picks-header">Make Your Picks</div>
              </div>
              {!userData.token ?
                <h2 style={{color: '#fff'}}>You must sign in or register to make picks.</h2>
              :
              contests.sort((a,b) => {
                if (a.entered && !b.entered) return -1;
                if (!a.entered && b.entered) return 1;
                if (a.next_upcoming_race_exists && !b.next_upcoming_race_exists) return -1;
                if (!a.next_upcoming_race_exists && b.next_upcoming_race_exists) return 1;
                else return new Date(a.next_upcoming_race_starts_at) - new Date(b.next_upcoming_race_starts_at)
              }).map((contest, contestIndex) =>
                contest.entered ?
                  <ContestEntryCard contest={contest} contestIndex={contestIndex} destination='picks' key={contest.id} />
                :
                  !contest.qualification_required && contest.contest_type !== 'Series' && <EnterThisContestCard contest={contest} handleEnterClick={handleEnterClick} />
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

const EnterThisContestCard = ({ contest, handleEnterClick }) => {
  return (
    <div className="make-picks-container">
      <div className="contest-details">
        <div className="contest-title">
          <div className="contest-button">
            <div className="contest-button-container">
              <div className="contest-title-text">{ contest.name }</div>
              <div className="carat">
                <img src="/img/white-carat-down.svg" alt="" className="changeCarat" />
              </div>
            </div>
          </div>
        </div>
        <div className="contest-reveal">
          <div className="race-selection-container">
            <p className="contest-have-not-entered">To make picks for this contest, be sure to ENTER!</p>
            <ol>
              <li className="contest-detail-button-container">
                <div className="outlined-button">
                  <Link to={'/contests/' + contest.id}>INFO</Link>
                </div>
                <div className="outlined-button">
                  <div className="detail-outlined-buttons" onClick={() => handleEnterClick(contest.id)}>ENTER</div>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}
